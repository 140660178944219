@import url(https://fonts.googleapis.com/css?family=Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
@File: Axolot Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

*** - Default CSS
*** - Prealoader CSS 
*** - Navbar CSS
*** - Main Banner CSS
*** - Features CSS
*** - About CSS
*** - Services CSS
*** - Vision CSS
*** - Fun Facts CSS
*** - Join Now CSS
*** - How It Works CSS
*** - Team CSS
*** - Testimonials CSS
*** - FAQ CSS
*** - prices CSS
*** - Partner CSS
*** - Subscribe CSS
*** - Page Title CSS
*** - Contact CSS
*** - Footer Area CSS
*** - Go Top CSS
*** - Router Transitions
*/
/* ==============
    Start Default CSS 
==================================== */
.text-theme-color {
  color: #506cea; }

body {
  padding: 0;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px; }

img {
  max-width: 100%; }

.d-table {
  width: 100%;
  height: 100%; }
  .d-table-cell {
    vertical-align: middle; }

a {
  text-decoration: none;
  color: #506cea;
  transition: 0.4s; }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222222; }

p {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.8;
  color: #6f8ba4; }

.pt-100 {
  padding-top: 100px; }

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.mb-30 {
  margin-bottom: 30px; }

.mt-30 {
  margin-top: 30px; }

.mt-100 {
  margin-top: 100px; }

.bg-gray {
  background-color: #edf5ff; }

/* Section Title */
.section-title {
  margin-bottom: 60px;
  text-align: center;
  margin-top: -6px; }
  .section-title span {
    display: block;
    font-size: 16px;
    margin-bottom: 6px;
    color: #506cea; }
  .section-title h3 {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-bottom: 20px;
    max-width: 520px; }
    .section-title h3::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 75px;
      height: 3px;
      border-radius: 5px;
      right: 0;
      margin: 0 auto;
      background: #506cea; }
  .section-title p {
    max-width: 550px;
    margin: 0 auto; }

/* Owl Carousel Btn */
.owl-theme .owl-nav {
  margin-top: 0; }
  .owl-theme .owl-nav [class*="owl-"] {
    font-size: 17px !important;
    margin: 0 !important;
    padding: 0 !important;
    background: #ffffff !important;
    display: inline-block !important;
    border-radius: 0 !important;
    position: absolute;
    left: 20px;
    color: #506cea !important;
    top: 22%;
    height: 150px;
    opacity: 0;
    overflow: hidden;
    width: 15px;
    line-height: 150px;
    transition: 0.4s; }
    .owl-theme .owl-nav [class*="owl-"]:hover {
      color: #ffffff !important;
      background: #506cea !important; }
  .owl-theme .owl-nav .owl-next {
    left: auto;
    right: 20px; }

.owl-theme:hover .owl-nav [class*="owl-"] {
  opacity: 1;
  overflow: visible;
  left: 0; }

.owl-theme:hover .owl-nav .owl-next {
  left: auto;
  right: 0; }

/* Btn Button */
.btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 27px;
  position: relative;
  overflow: hidden; }
  .btn::before {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 100px;
    transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0; }
  .btn:hover::before {
    background: #ffffff;
    transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg); }

.btn-primary {
  background-color: #506cea;
  border-color: #506cea;
  color: #ffffff; }
  .btn-primary.focus, .btn-primary:focus {
    box-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #506cea;
    border-color: #506cea; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #506cea;
    border-color: #506cea; }
  .btn-primary.disabled, .btn-primary:disabled {
    opacity: 1;
    background-color: #506cea;
    border-color: #506cea; }

.btn-secondary {
  color: #ffffff;
  background-color: #20c997;
  border-color: #20c997; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #20c997;
    border-color: #20c997; }
  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none; }
  .btn-secondary.focus, .btn-secondary:focus {
    box-shadow: none; }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #20c997;
    border-color: #20c997; }

/* ==============
    End Default CSS 
==================================== */
/* ==============
    Start Navbar CSS 
==================================== */
.navbar {
  padding: 20px 0; }
  .navbar img {
    opacity: 0;
    transition: 0.2s linear all; }
  .navbar.is-sticky {
    background: #ffffff;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
            animation: 500ms ease-in-out 0s normal none 1 running fadeInDown; }
    .navbar.is-sticky img {
      opacity: 1; }

.bg-light {
  background-color: #ffffff !important;
  z-index: 99; }
  .bg-light .navbar-toggler {
    border-color: #506cea; }
    .bg-light .navbar-toggler .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(88, 130, 253, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }
  .bg-light .navbar-brand {
    color: #222222;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 25px;
    font-weight: 700; }
    .bg-light .navbar-brand span {
      color: #506cea; }
  .bg-light .navbar-nav .nav-link {
    color: #333333;
    font-size: 16px;
    cursor: pointer;
    position: relative;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
    margin-left: 15px;
    margin-right: 15px; }
    .bg-light .navbar-nav .nav-link:focus, .bg-light .navbar-nav .nav-link:hover {
      color: #506cea; }
      .bg-light .navbar-nav .nav-link:focus::before, .bg-light .navbar-nav .nav-link:hover::before {
        width: 100%; }
    .bg-light .navbar-nav .nav-link:last-child {
      padding-right: 0; }
    .bg-light .navbar-nav .nav-link.active, .bg-light .navbar-nav .nav-link.show {
      color: #506cea; }
    .bg-light .navbar-nav .nav-link.active .nav-link, .bg-light .navbar-nav .nav-link.show > .nav-link {
      color: #506cea; }
      .bg-light .navbar-nav .nav-link.active .nav-link::before, .bg-light .navbar-nav .nav-link.show > .nav-link::before {
        width: 100%; }
    .bg-light .navbar-nav .nav-link::before {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 2px;
      transition: 0.4s;
      background: #506cea; }
  .bg-light .navbar-nav .dropdown .dropdown-menu {
    min-width: 15rem;
    padding: 15px 15px;
    margin: 0;
    top: 115%;
    background-color: #ffffff;
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    border: none;
    border-radius: 10px;
    display: inline-block;
    opacity: 0;
    visibility: hidden;
    transition-duration: 0.4s; }
    .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item {
      padding: 0;
      margin: 15px 0;
      background: transparent;
      color: #333333; }
      .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item:hover, .bg-light .navbar-nav .dropdown .dropdown-menu .dropdown-item.active {
        color: #506cea; }
  .bg-light .navbar-nav .dropdown:hover .dropdown-menu {
    top: 100%;
    opacity: 1;
    visibility: visible; }

/* ==============
    End Navbar CSS 
==================================== */
/* ==============
    Start Main Banner CSS 
==================================== */
.main-banner {
  height: 680px;
  position: relative; }

.main-banner-content {
  text-align: left;
  margin-top: 50px;
  position: relative;
  z-index: 2; }
  .main-banner-content h1 {
    font-size: 55px;
    font-weight: 500;
    margin-bottom: 20px; }
  .main-banner-content p {
    margin-bottom: 30px; }
  .main-banner-content .btn-primary {
    margin-right: 13px; }
  .main-banner-content .btn-secondary i {
    margin-right: 4px; }

.banner-img {
  height: 598.328px;
  text-align: center; }

.creative-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  background: #506cea;
  height: 100%;
  border-radius: 100% 0 0 0; }

.creative-bg-two {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  background: #506cea;
  height: 100%;
  border-radius: 0 0 0 100%; }

.main-banner-form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 0 0 35px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08); }
  .main-banner-form h3 {
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 35px;
    background: #20c997;
    color: #ffffff;
    border-radius: 2px;
    padding: 25px 0; }
  .main-banner-form form {
    padding-left: 25px;
    padding-right: 25px; }
    .main-banner-form form .form-control {
      border: 1px solid #eeeeee;
      font-size: 14px;
      border-radius: 2px; }
    .main-banner-form form select.form-control {
      height: 50px;
      cursor: pointer; }
    .main-banner-form form .btn {
      margin-top: 10px;
      padding: 12px 27px;
      margin-top: 15px;
      font-size: 16px;
      z-index: 1; }

.banner-form {
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 25px;
  position: relative;
  z-index: 1; }
  .banner-form::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: #ffffff;
    width: 96%;
    opacity: 0.62;
    height: 50%;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 3px; }
  .banner-form form .form-control {
    border: 1px solid #eeeeee;
    font-size: 14px;
    border-radius: 2px; }
  .banner-form form label {
    color: #6f8ba4;
    margin-bottom: 8px;
    font-size: 15px; }
  .banner-form form select.form-control {
    height: 50px;
    cursor: pointer; }
  .banner-form form .btn {
    margin-top: 10px; }

.hero-form {
  background: #ffffff;
  padding: 40px 25px;
  position: relative;
  z-index: 1;
  border: 5px solid #20c997; }
  .hero-form form .form-control {
    border: 1px solid #eeeeee;
    font-size: 14px;
    border-radius: 2px; }
  .hero-form form label {
    color: #6f8ba4;
    margin-bottom: 8px;
    font-size: 15px; }
  .hero-form form select.form-control {
    height: 50px;
    cursor: pointer; }
  .hero-form form .btn {
    display: block;
    width: 100%;
    margin-top: 25px; }
    .hero-form form .btn::before, .hero-form form .btn::after {
      display: none; }

/* ==============
    End Main Banner CSS 
==================================== */
/* ==============
    Start Features CSS 
==================================== */
.skills-area {
  padding-bottom: 70px; }
  .skills-area .section-title a,
  .skills-area .section-title a:hover {
    color: #506cea;
    cursor: pointer;
    font-weight: 500; }

.single-features {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff;
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.4s; }
  .single-features i {
    font-size: 40px;
    display: inline-block;
    -moz-transition: 0.4s;
    color: #506cea;
    transition: 0.4s; }
  .single-features h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: 0.4s; }
  .single-features p {
    transition: 0.4s; }
  .single-features:hover {
    background: #506cea; }
    .single-features:hover h3,
    .single-features:hover p,
    .single-features:hover i {
      color: #ffffff; }

/* ==============
    End Features CSS 
==================================== */
/* ==============
    Start About CSS 
==================================== */
.profile-area {
  position: relative; }

.about-content h3 {
  font-size: 26px;
  line-height: 35px;
  margin-bottom: 15px; }

.about-content h4 {
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 15px; }

.about-content p {
  margin-bottom: 25px; }

.about-content .pull-left {
  float: left;
  margin-right: 45px !important; }

.about-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none; }
  .about-content ul li {
    color: #6f8ba4;
    margin-top: 10px;
    font-weight: 400;
    text-align: left; }
    .about-content ul li:first-child {
      margin-top: 0; }
    .about-content ul li i {
      margin-right: 4px;
      color: #506cea; }

.about-content .btn {
  margin-top: 25px; }

.about-text span {
  display: inline-block;
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  width: 50px;
  height: 50px;
  background: #506cea;
  text-align: center;
  line-height: 50px;
  border-radius: 50%; }

.about-text h3 {
  font-size: 26px;
  margin-bottom: 15px;
  margin-top: 25px; }

.about-text ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 15px; }
  .about-text ul li {
    color: #6f8ba4;
    margin-top: 10px;
    font-weight: 400; }
    .about-text ul li i {
      margin-right: 4px;
      color: #506cea; }
    .about-text ul li:first-child {
      margin-top: 0; }

.about-text .blockquote {
  margin-bottom: 0;
  background: #506cea;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px; }
  .about-text .blockquote p {
    color: #ffffff; }

/* ==============
    End About CSS 
==================================== */
/* ==============
    Start Services CSS 
==================================== */
.services-area {
  padding-bottom: 70px; }

.single-skills {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: #ffffff;
  border-radius: 5px;
  height: 100%;
  padding: 30px;
  cursor: pointer;
  transition: 0.4s; }
  .single-skills .rate {
    color: #506cea;
    transition: 0.4s;
    font-size: 0.9rem; }
    .single-skills .rate.is-negative {
      opacity: 0.5; }
  .single-skills ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    .single-skills ul li {
      color: #6f8ba4;
      margin-top: 10px;
      font-weight: 400;
      text-align: left; }
      .single-skills ul li:first-child {
        margin-top: 0; }
  .single-skills h3 {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 20px;
    transition: 0.4s; }
  .single-skills p {
    transition: 0.4s; }
  .single-skills:hover {
    background: #506cea; }
    .single-skills:hover .rate {
      color: #ffffff; }
    .single-skills:hover h3,
    .single-skills:hover p,
    .single-skills:hover li {
      color: #ffffff; }

/* ==============
    End Services CSS 
==================================== */
/* ==============
    Start Vision CSS 
==================================== */
.tab {
  overflow: hidden; }
  .tab .tab_content .react-tabs .react-tabs__tab-list {
    list-style-type: none;
    margin-bottom: 40px !important;
    padding-left: 0 !important;
    border-bottom: 2px solid #edf5ff !important;
    padding-bottom: 35px !important;
    display: flex;
    flex-wrap: wrap; }
    .tab .tab_content .react-tabs .react-tabs__tab-list li {
      flex: 0 0 16.6666666667%;
      max-width: 16.6666666667%;
      padding-right: 15px;
      padding-left: 15px;
      text-align: center;
      color: #222222;
      font-size: 18px;
      font-weight: 500;
      text-decoration: none;
      display: inline-block;
      border: none;
      bottom: 0;
      box-shadow: unset !important;
      outline: 0 !important;
      padding: 0;
      transition: 0.4s; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:hover {
        color: #506cea; }
        .tab .tab_content .react-tabs .react-tabs__tab-list li:hover i {
          color: #ffffff;
          background: #506cea; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:first-child {
        padding-left: 0; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li:last-child {
        padding-right: 0; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li i {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background: #edf5ff;
        text-align: center;
        font-size: 30px;
        border-radius: 50%;
        margin-bottom: 15px;
        transition: 0.4s; }
    .tab .tab_content .react-tabs .react-tabs__tab-list .react-tabs__tab--selected {
      color: #506cea; }
      .tab .tab_content .react-tabs .react-tabs__tab-list .react-tabs__tab--selected i {
        color: #ffffff;
        background: #506cea; }
  .tab .tab_content .react-tabs .tabs_item {
    display: none; }
    .tab .tab_content .react-tabs .tabs_item:first-child {
      display: block; }
    .tab .tab_content .react-tabs .tabs_item .tabs_item_content h3 {
      font-size: 22px;
      margin-bottom: 15px; }
    .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      margin-top: 15px; }
      .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li {
        color: #6f8ba4;
        margin-top: 10px;
        font-weight: 400; }
        .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li:first-child {
          margin-top: 0; }
        .tab .tab_content .react-tabs .tabs_item .tabs_item_content ul li i {
          margin-right: 4px;
          color: #506cea; }

/* ==============
    End Vision CSS 
==================================== */
/* ==============
    Start Fun Facts CSS 
==================================== */
.funfacts-area {
  position: relative; }
  .funfacts-area::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #506cea;
    left: 0;
    opacity: 0.84; }

.funFact {
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  transition: 0.4s; }
  .funFact:hover {
    transform: translateY(-5px); }
  .funFact::before, .funFact::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 75px;
    background: #ffffff;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    transition: 0.4s; }
  .funFact::after {
    top: auto;
    bottom: 0; }
  .funFact i {
    font-size: 28px;
    background: #ffffff;
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    line-height: 61px;
    color: #506cea;
    border-radius: 50%;
    transition: 0.4s; }
  .funFact h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 20px;
    margin-bottom: 10px; }
  .funFact p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0; }
  .funFact:hover::before, .funFact:hover::after {
    height: 0; }
  .funFact:hover i {
    color: #ffffff;
    background: transparent; }

/* Fun Fact Two */
.fun-fact {
  position: relative;
  padding-left: 80px;
  transition: 0.4s; }
  .fun-fact i {
    font-size: 28px;
    background: #ffffff;
    display: inline-block;
    width: 60px;
    position: absolute;
    color: #506cea;
    left: 0;
    top: 0;
    height: 60px;
    border: 1px solid #ffffff;
    line-height: 61px;
    text-align: center;
    border-radius: 50%;
    transition: 0.4s; }
  .fun-fact h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 10px; }
  .fun-fact p {
    color: #ffffff;
    font-size: 20px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0; }
  .fun-fact:hover {
    transform: translateY(-5px); }
    .fun-fact:hover i {
      color: #ffffff;
      background: transparent; }

/* ==============
    End Fun Facts CSS 
==================================== */
/* ==============
    Start Join Now CSS 
==================================== */
.join-content {
  margin-top: 45px; }
  .join-content h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px; }
  .join-content p {
    margin-bottom: 25px; }
  .join-content .btn-primary {
    margin-right: 6px; }

/* ==============
    End Join Now CSS 
==================================== */
/* ==============
    Start How It Works CSS 
==================================== */
.single-box {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  position: relative; }
  .single-box span {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 84px;
    text-align: center;
    font-size: 30px;
    margin-bottom: 25px;
    background: #edf5ff;
    border-radius: 50%;
    font-weight: 700;
    transition: 0.4s; }
  .single-box h3 {
    font-size: 24px;
    margin-bottom: 20px; }
  .single-box:hover span {
    color: #ffffff;
    background: #506cea; }

/* ==============
    End How It Works CSS 
==================================== */
/* ==============
    Start Team CSS 
==================================== */
.our-team {
  text-align: center;
  position: relative; }
  .our-team .pic {
    border-radius: 50%;
    overflow: hidden;
    position: relative; }
    .our-team .pic::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(53, 53, 53, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      transform: scale(1);
      transition: 0.4s; }
  .our-team img {
    border-radius: 50%;
    transform: scale(1);
    transition: 0.4s; }
  .our-team .social {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 2;
    list-style-type: none;
    opacity: 0;
    transform: scale(1.2);
    transition: 0.4s; }
    .our-team .social li {
      display: inline-block; }
      .our-team .social li a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        color: #506cea;
        margin: 0 3px;
        background: #ffffff;
        text-decoration: none; }
        .our-team .social li a:hover {
          color: #ffffff;
          background: #506cea; }
  .our-team .title {
    font-size: 22px;
    font-weight: 700;
    color: #222222;
    text-transform: capitalize;
    margin-top: 25px;
    margin-bottom: 10px; }
  .our-team .post {
    display: inline-block;
    color: #6f8ba4; }
  .our-team:hover .pic::before {
    opacity: 1;
    transform: scale(0.95);
    visibility: visible; }
  .our-team:hover img {
    transform: scale(1.3); }
  .our-team:hover .social {
    opacity: 1;
    transform: scale(1); }

/* Team Style Two */
.single-team {
  text-align: center; }
  .single-team .pic {
    position: relative; }
    .single-team .pic::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.55);
      opacity: 0;
      z-index: 1;
      border: 2px solid #506cea;
      visibility: hidden;
      transform: scale(0.9);
      transition: 0.4s; }
    .single-team .pic::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(1);
      z-index: 2;
      visibility: hidden;
      transition: 0.4s; }
  .single-team .social-links {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 40px;
    left: 0;
    opacity: 0;
    z-index: 6;
    visibility: hidden;
    transform: scale(0.9);
    transition: 0.4s; }
    .single-team .social-links li {
      display: inline-block; }
      .single-team .social-links li a {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        line-height: 30px;
        border-radius: 50%;
        font-size: 15px;
        color: #ffffff;
        border: 1px solid #ffffff;
        text-decoration: none; }
        .single-team .social-links li a:hover {
          background: #506cea;
          border-color: #506cea; }
  .single-team .team-content {
    padding: 20px 0;
    background: #edf5ff;
    border-top: 2px solid #506cea; }
    .single-team .team-content .title {
      font-size: 22px;
      font-weight: 700;
      color: #222222;
      text-transform: capitalize;
      margin-bottom: 10px;
      position: relative; }
    .single-team .team-content .post {
      display: inline-block;
      color: #6f8ba4;
      position: relative; }
  .single-team:hover .pic::before, .single-team:hover .pic::after {
    opacity: 1;
    visibility: visible; }
  .single-team:hover .pic::before {
    transform: scale(1); }
  .single-team:hover .pic::after {
    transform: scale(0.9); }
  .single-team:hover .social-links {
    opacity: 1;
    visibility: visible;
    transform: scale(1); }

/* ==============
    End Team CSS 
==================================== */
/* ==============
    Start Testimonials CSS 
==================================== */
.testimonials-area {
  padding-bottom: 70px;
  position: relative; }
  .testimonials-area .section-title sup {
    color: #506cea;
    font-size: 1.2rem;
    margin-left: 5px; }
  .testimonials-area .source {
    color: #506cea;
    font-size: 0.9rem; }

.testimonials-item {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.4s; }
  .testimonials-item:hover .more,
  .testimonials-item:hover .less {
    color: #abb9f5; }
  .testimonials-item .client-info {
    position: relative;
    padding-left: 110px;
    margin-bottom: 40px; }
    .testimonials-item .client-info .img {
      position: absolute;
      left: 0;
      top: 0; }
      .testimonials-item .client-info .img img {
        border-radius: 50%; }
    .testimonials-item .client-info .client-title {
      display: inline-block;
      margin-top: 14px; }
      .testimonials-item .client-info .client-title h4 {
        font-size: 20px;
        margin-bottom: 8px;
        text-transform: uppercase; }
      .testimonials-item .client-info .client-title span {
        display: inline-block;
        color: #506cea; }
      .testimonials-item .client-info .client-title span,
      .testimonials-item .client-info .client-title h4 {
        position: relative;
        z-index: 1;
        transition: 0.4s; }
  .testimonials-item p {
    position: relative;
    z-index: 1;
    transition: 0.4s; }
  .testimonials-item i {
    position: absolute;
    right: 28px;
    bottom: 20px;
    font-size: 98px;
    color: #edf5ff;
    transition: 0.4s; }
  .testimonials-item:hover {
    background: #506cea; }
    .testimonials-item:hover .client-info .client-title span,
    .testimonials-item:hover .client-info .client-title h4 {
      color: #ffffff; }
    .testimonials-item:hover p {
      color: #ffffff; }
    .testimonials-item:hover i {
      color: #636363; }

/* ==============
    End Testimonials CSS 
==================================== */
/* ==============
    Start FAQ CSS 
==================================== */
.accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none !important; }
  .accordion .accordion-item {
    display: block;
    margin-bottom: 15px; }
    .accordion .accordion-item:last-child {
      margin-bottom: 0; }
  .accordion p {
    margin-top: 10px; }
  .accordion .accordion-title {
    display: block;
    color: #ffffff !important;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    background: #506cea; }
    .accordion .accordion-title:hover {
      background-color: #20c997; }
  .accordion .active .accordion-title {
    background: #20c997;
    border-radius: 0; }
  .accordion .accordion-content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 15px 15px 0; }
  .accordion .accordion-content.show {
    display: block; }
  .accordion .accordion__item {
    margin-bottom: 10px; }
    .accordion .accordion__item:last-child {
      margin-bottom: 0; }
  .accordion .accordion__button {
    background-color: #506cea;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    border: none;
    outline: 0;
    border-radius: 30px;
    transition: 0.5s; }
    .accordion .accordion__button:hover {
      background-color: #20c997; }
  .accordion .accordion__panel p {
    margin-top: 0; }
  .accordion .accordion__item + .accordion__item {
    border: none; }

/* ==============
    End FAQ CSS 
==================================== */
/* ==============
    Start Prices CSS 
==================================== */
.pricesTable {
  padding-bottom: 30px;
  background: #ffffff;
  border-radius: 5px;
  text-align: center;
  position: relative;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  transition: 0.4s; }
  .pricesTable i {
    color: #506cea;
    font-size: 1.3rem;
    vertical-align: middle; }
  .pricesTable .title {
    padding: 30px 20px 192px;
    margin: 0 0 30px 0;
    font-size: 25px;
    color: #ffffff;
    overflow: hidden;
    position: relative;
    background: #506cea; }
    .pricesTable .title::before, .pricesTable .title::after {
      content: "";
      width: 280px;
      height: 200px;
      border-radius: 80px;
      background: #ffffff;
      position: absolute;
      bottom: -175px;
      left: -46px;
      transform: rotate(-85deg); }
    .pricesTable .title::after {
      border-radius: 100px;
      bottom: auto;
      top: 150px;
      left: auto;
      right: -70px;
      transform: rotate(-40deg); }
  .pricesTable .price-value {
    display: inline-block;
    width: 140px;
    height: 140px;
    line-height: 65px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 30px 0;
    font-size: 30px;
    font-weight: 600;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.4s; }
  .pricesTable .month {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 0; }
  .pricesTable .prices-content {
    list-style: none;
    padding: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 25px;
    transition: 0.4s; }
    .pricesTable .prices-content li {
      margin-bottom: 12px;
      color: #737373; }
      .pricesTable .prices-content li:last-child {
        margin-bottom: 0; }
  .pricesTable:hover .price-value {
    color: #ffffff;
    background: #506cea; }

/* prices Style Two */
.prices-table {
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  padding: 50px 0 30px;
  text-align: center;
  margin-top: 33px;
  border-radius: 5px;
  position: relative;
  background: #ffffff;
  cursor: pointer; }
  .prices-table .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    margin: 0 auto;
    font-size: 25px;
    position: absolute;
    color: #506cea;
    top: -34px;
    left: 0;
    right: 0;
    transition: 0.4s; }
  .prices-table .prices-table-header {
    margin-bottom: 25px;
    margin-top: 20px;
    padding: 20px 0;
    background: #506cea; }
  .prices-table .title {
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    color: #ffffff; }
  .prices-table .price-value {
    display: inline-block;
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    border-bottom: 3px solid #ffffff; }
    .prices-table .price-value b {
      font-weight: 500;
      font-size: 15px; }
  .prices-table .prices-content {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0; }
    .prices-table .prices-content li {
      margin-bottom: 12px;
      color: #737373; }
      .prices-table .prices-content li:last-child {
        margin-bottom: 0; }
  .prices-table:hover .icon {
    color: #ffffff;
    background: #506cea; }

/* ==============
    End prices CSS 
==================================== */
/* ==============
    Start Partner CSS 
==================================== */
.partner-area .item {
  text-align: center; }

.partner-area .owl-item img {
  border: 1px solid #506cea;
  display: inline-block; }

/* ==============
    End Partner CSS 
==================================== */
/* ==============
    End Subscribe CSS 
==================================== */
.subscribe-area {
  text-align: center;
  position: relative;
  background: #506cea; }

.newsletter {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: #ffffff;
  padding: 35px;
  border-radius: 5px; }
  .newsletter h4 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 30px; }
    .newsletter h4 span {
      text-transform: uppercase;
      font-weight: 600;
      color: #506cea; }
  .newsletter .subscribe-form {
    position: relative; }
    .newsletter .subscribe-form .form-control {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
      border: none;
      height: 75px;
      border-radius: 50px;
      padding-left: 20px;
      font-size: 17px; }
    .newsletter .subscribe-form .btn {
      position: absolute;
      right: 7px;
      border-radius: 30px;
      top: 8px;
      height: 60px;
      text-transform: uppercase;
      width: 175px; }
    .newsletter .subscribe-form .validation-danger {
      margin-top: 15px;
      color: red; }
    .newsletter .subscribe-form .validation-success {
      margin-top: 15px; }

.subs-sending-msg {
  color: black; }

.subs-sending-msg {
  color: #6993ff; }

.subs-error-msg {
  color: #ff7344; }

/* ==============
    End Subscribe CSS 
==================================== */
/* ==============
    Start Page Title CSS 
==================================== */
.page-title {
  position: relative;
  height: 350px;
  background: #506cea; }
  .page-title .page-title-content {
    text-align: center;
    position: relative;
    margin-top: -50px; }
    .page-title .page-title-content h3 {
      font-size: 25px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: #ffffff; }
    .page-title .page-title-content ul {
      padding: 0;
      margin: 0;
      list-style-type: none; }
      .page-title .page-title-content ul li {
        display: inline-block;
        color: #eeeeee;
        font-size: 16px; }
        .page-title .page-title-content ul li a {
          display: inline-block;
          color: #ffffff;
          text-decoration: none; }

/* ==============
    End Page Title CSS 
==================================== */
/* ==============
    Start Contact CSS 
==================================== */
.contact-box {
  background: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 20px 20px 90px;
  border-radius: 5px;
  position: relative;
  transition: 0.4s; }
  .contact-box .icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    border: 1px dashed #506cea;
    color: #506cea;
    border-radius: 50%;
    font-size: 22px;
    transition: 0.4s; }
  .contact-box .content h4 {
    font-size: 20px;
    margin-bottom: 12px; }
  .contact-box .content p a {
    color: #777777;
    text-decoration: none; }
    .contact-box .content p a:hover {
      color: #506cea; }
  .contact-box:hover {
    transform: translateY(-5px); }
    .contact-box:hover .icon {
      color: #ffffff;
      background: #506cea; }

#map {
  width: 100%;
  height: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: inherit; }

.googleMap {
  margin: 40px 0; }

.contact-text h3 {
  font-size: 22px;
  margin-bottom: 0; }

.contact-text p {
  margin-top: 20px; }

.form-control {
  height: 45px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  box-shadow: unset !important;
  transition: 0.4s; }
  .form-control:focus {
    box-shadow: unset;
    padding-left: 20px; }

#contactForm .list-unstyled li {
  color: red; }

#contactForm .btn {
  text-transform: uppercase;
  padding: 10px 35px;
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
  z-index: 1; }

#contactForm .contactMsg {
  color: #506cea; }

/* ==============
    End Contact CSS 
==================================== */
/* ==============
    Start Footer CSS 
==================================== */
.footer-area {
  padding-bottom: 0; }

.single-footer {
  text-align: left; }
  .single-footer .logo {
    margin-top: -6px;
    margin-bottom: 15px; }
    .single-footer .logo a {
      color: #222222;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 700;
      text-decoration: none; }
      .single-footer .logo a span {
        color: #506cea; }
  .single-footer .btn {
    margin-top: 15px; }
  .single-footer h3 {
    font-size: 24px;
    margin-top: -5px;
    margin-bottom: 25px; }
  .single-footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .single-footer ul li a {
      color: #6f8ba4;
      margin-top: 8px;
      display: block;
      text-decoration: none;
      font-size: 17px; }
      .single-footer ul li a i {
        margin-right: 4px; }
      .single-footer ul li a:hover {
        padding-left: 7px;
        color: #506cea; }
    .single-footer ul li:first-child a {
      margin-top: 0; }
  .single-footer .contact-info {
    margin-top: 15px; }
    .single-footer .contact-info li {
      margin-bottom: 10px;
      color: #737373;
      position: relative;
      padding-left: 25px; }
      .single-footer .contact-info li:last-child {
        margin-bottom: 0; }
      .single-footer .contact-info li i {
        position: absolute;
        left: 0;
        top: 3px;
        color: #506cea;
        font-size: 20px; }

.copyright-area {
  margin-top: 100px;
  background: #edf5ff;
  padding: 40px 0; }
  .copyright-area p {
    color: #222222;
    margin-top: 6px; }
  .copyright-area ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .copyright-area ul li {
      display: inline-block; }
      .copyright-area ul li a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 33px;
        text-align: center;
        margin-left: 4px;
        border-radius: 50%;
        border: 1px solid #506cea;
        text-decoration: none; }
        .copyright-area ul li a:hover {
          color: #ffffff;
          background: #506cea;
          border-color: #506cea; }

/* ==============
    End Footer CSS 
==================================== */
/* ==============
    Scroll Top
==================================== */
aside.ScrollUpButton__Container.ScrollUpButton__Toggled:hover {
  background-color: #506cea !important;
  transition: 1s !important;
  outline: 0 !important; }

/* ==============
    Router Transitions
==================================== */
.fade-enter {
  opacity: 0.01; }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in; }

.fade-exit {
  opacity: 1; }
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in; }

div.transition-group {
  position: relative; }

section.route-section {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }

/* ==============
    Start Repair Main Banner CSS 
==================================== */
.repair-main-banner {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-top: 200px;
  padding-bottom: 300px; }
  .repair-main-banner::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: -1;
    opacity: 0.6; }

.repair-banner-content h2 {
  color: #ffffff;
  line-height: 1.2;
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 25px; }

.repair-banner-content p {
  max-width: 550px;
  margin-bottom: 25px;
  color: #ffffff;
  font-size: 17px; }

.repair-banner-content .btn {
  text-transform: uppercase; }

.banner-video a {
  width: 100px;
  display: inline-block;
  height: 100px;
  line-height: 100px;
  font-size: 30px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 50%;
  z-index: 1;
  color: #4457c3;
  margin-left: -40px;
  position: relative;
  transition: 0.5s; }
  .banner-video a::after {
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    -webkit-animation: ripple 1.6s ease-out infinite;
            animation: ripple 1.6s ease-out infinite;
    opacity: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5); }
  .banner-video a:hover, .banner-video a:focus {
    background-color: #4457c3;
    color: #ffffff; }

.repair-home-slides.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 0;
  position: absolute;
  left: 9.5%;
  bottom: 28%; }
  .repair-home-slides.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 4px;
    margin: 0 3px;
    background: #d5d6d6;
    transition: 0.4s;
    border-radius: 0; }
  .repair-home-slides.owl-theme .owl-dots .owl-dot:hover span, .repair-home-slides.owl-theme .owl-dots .owl-dot:focus span {
    background: #ffffff; }
  .repair-home-slides.owl-theme .owl-dots .owl-dot.active span {
    width: 30px;
    background: #ffffff; }

@-webkit-keyframes ripple {
  0%,
  35% {
    transform: scale(0);
    opacity: 1; }
  50% {
    transform: scale(1.5);
    opacity: 0.8; }
  100% {
    opacity: 0;
    transform: scale(2); } }

@keyframes ripple {
  0%,
  35% {
    transform: scale(0);
    opacity: 1; }
  50% {
    transform: scale(1.5);
    opacity: 0.8; }
  100% {
    opacity: 0;
    transform: scale(2); } }

/* ==============
    End Repair Main Banner CSS 
==================================== */
/* ==============
    Start Repair Boxes CSS 
==================================== */
.repair-boxes-area {
  background-color: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: -150px;
  margin-bottom: -45px;
  padding-top: 40px;
  padding-bottom: 40px; }
  .repair-boxes-area::before {
    content: "";
    position: absolute;
    left: 0;
    height: 90%;
    bottom: 0;
    width: 201%;
    background: #ffffff;
    z-index: -1;
    transform: skewY(177deg); }

.single-repair-box {
  text-align: center;
  border-radius: 5px;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 45px 25px;
  margin-bottom: 45px; }
  .single-repair-box i {
    font-size: 50px;
    color: #47d167;
    transition: 0.5s; }
  .single-repair-box h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 15px;
    transition: 0.5s; }
  .single-repair-box a {
    font-weight: 600;
    margin-top: 20px;
    color: #222222;
    display: inline-block;
    transition: 0.5s; }
    .single-repair-box a:hover, .single-repair-box a:focus {
      color: #4457c3;
      text-decoration: none;
      letter-spacing: 1.5px; }
  .single-repair-box .img-box img:first-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    opacity: 0.7;
    transition: 0.5s; }
  .single-repair-box .img-box img:last-child {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s; }
  .single-repair-box::before, .single-repair-box::after {
    content: "";
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
    width: 100%;
    background: #4457c3;
    border-radius: 5px 5px 0 0;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden; }
  .single-repair-box::after {
    top: auto;
    bottom: -40px;
    border-radius: 0 0 5px 5px; }
  .single-repair-box.active {
    border-radius: 0;
    background-color: #4457c3; }
    .single-repair-box.active i {
      color: #ffffff; }
    .single-repair-box.active h3 {
      color: #ffffff; }
    .single-repair-box.active p {
      color: #ffffff; }
    .single-repair-box.active a {
      color: #ffffff; }
    .single-repair-box.active::before, .single-repair-box.active::after {
      opacity: 1;
      visibility: visible; }
    .single-repair-box.active .img-box img:first-child {
      opacity: 0;
      visibility: hidden; }
    .single-repair-box.active .img-box img:last-child {
      opacity: 0.35;
      visibility: visible; }

/* ==============
    End Repair Boxes CSS 
==================================== */
/* ==============
    Start Repair About CSS 
==================================== */
.repair-about-content .section-title {
  text-align: left;
  margin-bottom: 25px; }
  .repair-about-content .section-title h3 {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0; }
    .repair-about-content .section-title h3::before {
      display: none; }
  .repair-about-content .section-title p {
    margin-left: 0;
    margin-right: 0; }

.repair-about-content ul {
  padding-left: 0;
  list-style-type: none;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: -15px;
  margin-right: -15px; }
  .repair-about-content ul li {
    float: left;
    width: 50%;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    font-weight: 600; }
    .repair-about-content ul li i {
      color: #47d167;
      margin-right: 3px; }

.repair-about-content .read-more-btn {
  display: inline-block;
  margin-top: 20px;
  text-transform: uppercase;
  color: #47d167;
  font-size: 14px;
  font-weight: 700; }
  .repair-about-content .read-more-btn:hover {
    color: #4457c3;
    text-decoration: none; }

.repair-about-image {
  position: relative;
  width: 100%;
  height: 100%; }
  .repair-about-image img {
    position: absolute; }
    .repair-about-image img:nth-child(1) {
      left: 0;
      top: 0;
      z-index: 1; }
    .repair-about-image img:nth-child(2) {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      text-align: center;
      z-index: 2;
      margin: 0 auto;
      border: 5px solid #f8fbff;
      width: 350px; }
    .repair-about-image img:nth-child(3) {
      right: 0;
      bottom: 0;
      z-index: 1; }
    .repair-about-image img:nth-child(4) {
      right: 0;
      top: 0; }
    .repair-about-image img:nth-child(5) {
      left: 0;
      bottom: 0; }

/* ==============
    End Repair About CSS 
==================================== */
/* ==============
    Start Repair Feedback CSS 
==================================== */
.feedback-area {
  position: relative;
  z-index: 1; }

.single-repair-feedback {
  background-color: #f8fbff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1; }
  .single-repair-feedback .client-info {
    margin-bottom: 25px;
    position: relative;
    padding-left: 90px;
    padding-top: 18px; }
    .single-repair-feedback .client-info img {
      display: inline-block !important;
      width: 70px !important;
      height: 70px;
      position: absolute;
      left: 0;
      top: 0; }
    .single-repair-feedback .client-info h3 {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px; }
    .single-repair-feedback .client-info span {
      display: block;
      color: #4457c3; }
  .single-repair-feedback ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 15px; }
    .single-repair-feedback ul li {
      display: inline-block;
      color: #ff5202; }
  .single-repair-feedback .icofont-quote-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 0.05;
    font-size: 180px; }

.repair-feedback-slides.owl-theme .owl-dots {
  line-height: 0.1;
  margin-top: 45px; }
  .repair-feedback-slides.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 4px;
    margin: 0 3px;
    background: #e7e7e7;
    transition: 0.4s;
    border-radius: 0; }
  .repair-feedback-slides.owl-theme .owl-dots .owl-dot:hover span, .repair-feedback-slides.owl-theme .owl-dots .owl-dot:focus span {
    background: #4457c3; }
  .repair-feedback-slides.owl-theme .owl-dots .owl-dot.active span {
    width: 30px;
    background: #4457c3; }

.map-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: -1; }

/* ==============
    End Repair Feedback CSS 
==================================== */
/* ==============
    Start Gallery CSS 
==================================== */
.gallery-area {
  padding-bottom: 70px; }
  .gallery-area .section-title {
    margin-bottom: 40px; }

.single-gallery-item {
  overflow: hidden;
  margin-bottom: 30px; }
  .single-gallery-item a {
    display: block; }
    .single-gallery-item a img {
      transition: all 1.5s ease-out; }
  .single-gallery-item:hover a img, .single-gallery-item:focus a img {
    transform: scale(1.4); }

.gallery-shorting-menu {
  display: block;
  text-align: center;
  margin-bottom: 40px;
  padding: 0;
  background-color: transparent;
  border-radius: 0; }
  .gallery-shorting-menu .filter {
    border-radius: 0;
    outline: 0;
    background-color: transparent;
    border: 1px solid #eeeeee;
    color: #222222;
    text-transform: capitalize;
    margin: 0 3px;
    padding: 9px 24px;
    transition: 0.4s;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500; }
    .gallery-shorting-menu .filter:hover, .gallery-shorting-menu .filter:focus, .gallery-shorting-menu .filter.active {
      background-color: #4457c3;
      color: #ffffff;
      border-color: #4457c3; }

/* ==============
    End Gallery CSS 
==================================== */
/*=============================
Start Repair Partner CSS
===============================*/
.repair-partner-area {
  background-color: #edf5ff;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px; }

.single-repain-client {
  text-align: center; }
  .single-repain-client img {
    width: auto !important;
    height: auto;
    display: inline-block !important; }

/* ==============
    End Repair Partner CSS 
==================================== */
/* ==============
    Start Repair Blog CSS 
==================================== */
.repair-blog-area {
  padding-bottom: 70px; }

.single-repair-blog-post {
  margin-bottom: 30px; }
  .single-repair-blog-post .blog-image {
    overflow: hidden;
    position: relative; }
    .single-repair-blog-post .blog-image a {
      display: block; }
      .single-repair-blog-post .blog-image a img {
        transition: all 1.5s ease-out; }
    .single-repair-blog-post .blog-image .tags {
      position: absolute;
      left: 15px;
      z-index: 2;
      top: 15px;
      transition: 0.4s; }
      .single-repair-blog-post .blog-image .tags a {
        background: #47d167;
        color: #ffffff;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 5px 10px 3px;
        text-decoration: none;
        font-weight: 600;
        font-size: 13px; }
  .single-repair-blog-post .blog-content {
    margin-top: 20px; }
    .single-repair-blog-post .blog-content h3 {
      line-height: 1.4;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 600; }
      .single-repair-blog-post .blog-content h3 a {
        color: #222222; }
        .single-repair-blog-post .blog-content h3 a:hover, .single-repair-blog-post .blog-content h3 a:focus {
          color: #4457c3;
          text-decoration: none; }
    .single-repair-blog-post .blog-content ul {
      list-style-type: none;
      margin-top: 15px;
      margin-bottom: 0;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 15px;
      padding-top: 15px;
      padding-left: 0; }
      .single-repair-blog-post .blog-content ul li {
        display: inline-block;
        color: #6f8ba4;
        position: relative;
        margin-right: 18px; }
        .single-repair-blog-post .blog-content ul li::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          height: 2px;
          background: #4457c3;
          left: -13px; }
        .single-repair-blog-post .blog-content ul li a {
          color: #6f8ba4; }
          .single-repair-blog-post .blog-content ul li a:hover, .single-repair-blog-post .blog-content ul li a:focus {
            color: #4457c3;
            text-decoration: none; }
        .single-repair-blog-post .blog-content ul li:first-child::before {
          display: none; }
        .single-repair-blog-post .blog-content ul li:last-child {
          float: right;
          margin-right: 0; }
          .single-repair-blog-post .blog-content ul li:last-child::before {
            display: none; }
          .single-repair-blog-post .blog-content ul li:last-child a {
            text-transform: uppercase;
            color: #47d167; }
            .single-repair-blog-post .blog-content ul li:last-child a:hover, .single-repair-blog-post .blog-content ul li:last-child a:focus {
              color: #4457c3; }
  .single-repair-blog-post:hover .blog-image a img, .single-repair-blog-post:focus .blog-image a img {
    transform: scale(1.3); }

/* ==============
    End Repair Blog CSS 
==================================== */
/* ==============
    Start Repair Subscribe CSS 
==================================== */
.repair-subscribe-area {
  position: relative;
  margin-top: -100px;
  margin-bottom: 100px; }

.repair-subscribe-content {
  text-align: center;
  border-radius: 5px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px; }
  .repair-subscribe-content h2 {
    color: #ffffff;
    margin-bottom: 30px;
    font-size: 30px; }
  .repair-subscribe-content form {
    overflow: hidden; }
    .repair-subscribe-content form .form-group {
      float: left;
      margin-bottom: 0;
      width: 29%;
      padding-right: 15px; }
      .repair-subscribe-content form .form-group .input-newsletter {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid #ffffff;
        background: transparent;
        outline: 0;
        color: #ffffff;
        padding-bottom: 10px;
        padding-top: 12px; }
        .repair-subscribe-content form .form-group .input-newsletter::-webkit-input-placeholder {
          color: #ffffff; }
        .repair-subscribe-content form .form-group .input-newsletter::-ms-input-placeholder {
          color: #ffffff; }
        .repair-subscribe-content form .form-group .input-newsletter::placeholder {
          color: #ffffff; }
    .repair-subscribe-content form .validation-danger,
    .repair-subscribe-content form .validation-success {
      color: #ffffff; }
    .repair-subscribe-content form .btn {
      float: left;
      width: 13%; }
    .repair-subscribe-content form .btn-primary {
      color: #ffffff;
      border-color: #47d167;
      background-color: #47d167; }
      .repair-subscribe-content form .btn-primary.disabled, .repair-subscribe-content form .btn-primary:disabled {
        color: #ffffff;
        background-color: #47d167;
        border-color: #47d167; }

/* ==============
    End Repair Subscribe CSS 
==================================== */
/* ==============
    Start Repair Footer CSS 
==================================== */
.repair-footer-area {
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .repair-footer-area::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 201%;
    background: #161616;
    z-index: -2;
    transform: skewY(177deg); }

.single-footer-widget .logo {
  margin-top: -10px; }
  .single-footer-widget .logo a {
    color: #ffffff;
    text-transform: uppercase;
    display: block;
    font-size: 30px;
    font-weight: 700; }
    .single-footer-widget .logo a span {
      color: #4457c3;
      display: inline-block; }

.single-footer-widget p {
  color: #f2f2f2;
  margin-top: 15px;
  margin-bottom: 20px; }

.single-footer-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none; }

.single-footer-widget h3 {
  color: #ffffff;
  margin-bottom: 27px;
  font-size: 20px;
  font-weight: 600; }

.single-footer-widget .services-list li a {
  margin-bottom: 12px;
  display: inline-block;
  color: #f2f2f2; }
  .single-footer-widget .services-list li a:hover {
    padding-left: 10px;
    color: #47d167; }

.single-footer-widget .services-list li:last-child a {
  margin-bottom: 0; }

.single-footer-widget .usefull-links li a {
  margin-bottom: 12px;
  display: inline-block;
  color: #f2f2f2; }
  .single-footer-widget .usefull-links li a:hover {
    padding-left: 10px;
    color: #47d167; }

.single-footer-widget .usefull-links li:last-child a {
  margin-bottom: 0; }

.single-footer-widget .contact-list li {
  margin-bottom: 12px;
  color: #f2f2f2;
  position: relative; }
  .single-footer-widget .contact-list li a {
    color: #f2f2f2;
    display: inline-block; }
    .single-footer-widget .contact-list li a:hover {
      color: #47d167; }
  .single-footer-widget .contact-list li:last-child {
    margin-bottom: 0; }
  .single-footer-widget .contact-list li span {
    font-weight: 600; }

.single-footer-widget .working-hours li {
  margin-bottom: 12px;
  color: #f2f2f2;
  position: relative; }
  .single-footer-widget .working-hours li:last-child {
    margin-bottom: 0; }

.social-links li {
  display: inline-block;
  margin-right: 2px; }
  .social-links li a {
    width: 35px;
    height: 35px;
    text-align: center;
    display: inline-block;
    line-height: 35px;
    border-radius: 50%;
    font-size: 14px;
    background-color: #343434;
    color: #ffffff; }
    .social-links li a:hover, .social-links li a:focus {
      background: #47d167;
      color: #ffffff; }
  .social-links li:last-child {
    margin-right: 0; }

.repair-copyright-area {
  background: #000000;
  margin-top: 100px;
  padding-top: 30px;
  padding-bottom: 30px; }
  .repair-copyright-area p {
    color: #ffffff;
    font-size: 15px; }
  .repair-copyright-area ul {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .repair-copyright-area ul li {
      display: inline-block;
      position: relative;
      padding-right: 20px;
      font-size: 15px; }
      .repair-copyright-area ul li::before {
        content: "";
        position: absolute;
        left: -13px;
        top: 5px;
        background: #7c7c7c;
        width: 2px;
        height: 14px; }
      .repair-copyright-area ul li:first-child::before {
        display: none; }
      .repair-copyright-area ul li a {
        display: inline-block;
        color: #f8f8f8; }
        .repair-copyright-area ul li a:hover {
          color: #47d167; }

/* ==============
    End Repair Footer CSS 
==================================== */
.CalendarDay__highlighted_calendar {
  background-color: orangered !important;
  color: #fff !important; }
  .CalendarDay__highlighted_calendar:hover, .CalendarDay__highlighted_calendar.CalendarDay__today {
    background-color: #e63e00 !important; }

.CalendarDay__blocked_calendar {
  background-color: #e4e5e6 !important; }
  .CalendarDay__blocked_calendar.CalendarDay__today {
    background-color: #cacccd !important; }

.DayPicker {
  margin: auto; }

.DayPicker__infos div {
  display: inline-block;
  height: 18px;
  width: 18px; }
  .DayPicker__infos div.available {
    background-color: #20c997; }
  .DayPicker__infos div.unavailable {
    background-color: #cacccd; }
  .DayPicker__infos div.booked {
    background-color: orangered; }

.fallback .spinner-border {
  color: #506cea; }

@media only screen and (max-width: 767px) {
  .mt-100 {
    margin-top: 40px; }
  .pt-100 {
    padding-top: 60px; }
  .ptb-100 {
    padding-top: 60px;
    padding-bottom: 60px; }
  .btn {
    font-size: 15px;
    padding: 9px 17px; }
  .owl-theme:hover .owl-nav [class*="owl-"] {
    left: 2px; }
  .owl-theme:hover .owl-nav .owl-next {
    left: auto;
    right: 2px; }
  .section-title {
    margin-bottom: 40px; }
    .section-title h3 {
      font-size: 22px; }
  .navbar-expand-lg .container,
  .navbar-expand-lg .container-fluid {
    padding-right: 15px;
    padding-left: 15px; }
  .navbar-light .navbar-toggler {
    outline: 0;
    padding: 4px 10px; }
    .navbar-light .navbar-toggler .navbar-toggler-icon {
      width: 1.3em;
      height: 1.3em; }
  .navbar-light .navbar-nav {
    margin-top: 10px; }
    .navbar-light .navbar-nav .nav-link {
      padding-left: 0;
      padding-right: 0; }
    .navbar-light .navbar-nav .dropdown .dropdown-menu {
      position: absolute; }
  .main-banner {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 50px; }
  .main-banner-content {
    text-align: left;
    margin-top: 0; }
    .main-banner-content h1 {
      font-size: 30px;
      margin-bottom: 20px;
      line-height: 40px;
      text-align: center; }
    .main-banner-content .btn {
      display: block;
      margin: 0;
      margin-bottom: 3px; }
  .main-banner-form {
    margin-top: 30px;
    padding-bottom: 25px; }
    .main-banner-form h3 {
      font-size: 20px;
      margin-bottom: 25px;
      padding: 18px 0; }
    .main-banner-form form {
      padding-left: 18px;
      padding-right: 18px; }
  .banner-form {
    padding: 25px 18px;
    margin-top: 30px; }
  .hero-form {
    padding: 25px 18px;
    margin-top: 30px;
    border-width: 3px; }
  .banner-img {
    margin-top: 40px; }
  .creative-bg {
    height: 50%; }
  .creative-bg-two {
    display: none; }
  .main-banner .bg-bottom {
    bottom: auto;
    top: 0; }
  .skills-area {
    padding-bottom: 30px; }
  .single-features {
    padding: 20px;
    margin-bottom: 30px; }
  .about-content h3 {
    font-size: 20px;
    line-height: 32px; }
  .about-content .pull-left {
    float: none;
    margin-right: 0 !important;
    margin-bottom: 10px; }
  .about-img {
    margin-top: 40px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 35px; }
  .about-text {
    margin-top: 40px;
    margin-bottom: 40px; }
    .about-text span {
      font-size: 17px;
      width: 40px;
      height: 40px;
      line-height: 43px; }
    .about-text h3 {
      font-size: 20px;
      margin-top: 20px; }
  .services-area {
    padding-bottom: 30px; }
  .single-skills {
    padding: 20px;
    text-align: center; }
  .tab .tab_content .react-tabs .react-tabs__tab-list {
    margin-bottom: 25px !important;
    padding-bottom: 5px !important; }
    .tab .tab_content .react-tabs .react-tabs__tab-list li {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 25px;
      text-align: center;
      font-size: 16px; }
      .tab .tab_content .react-tabs .react-tabs__tab-list li i {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 26px;
        margin-bottom: 15px; }
  .tab .tab_content .react-tabs .tabs_item .tabs_item_content {
    margin-bottom: 30px;
    margin-top: 30px; }
    .tab .tab_content .react-tabs .tabs_item .tabs_item_content h3 {
      font-size: 20px; }
  .funfacts-area {
    padding-top: 60px;
    padding-bottom: 20px; }
  .funFact {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 40px; }
    .funFact::before, .funFact::after {
      display: none; }
    .funFact i {
      font-size: 25px;
      line-height: 54px;
      width: 55px;
      height: 55px; }
    .funFact h3 {
      font-size: 30px; }
    .funFact p {
      font-size: 18px;
      line-height: 20px; }
  .fun-fact {
    padding-left: 0;
    text-align: center;
    margin-bottom: 40px; }
    .fun-fact i {
      font-size: 20px;
      width: 50px;
      height: 50px;
      line-height: 50px;
      position: relative;
      margin-bottom: 15px; }
    .fun-fact h3 {
      font-size: 30px; }
    .fun-fact p {
      font-size: 17px; }
  .newsletter .subscribe-form .form-control {
    font-size: 14px;
    height: 60px; }
  .newsletter .subscribe-form .btn {
    position: relative;
    display: block;
    width: 100%;
    height: 50px; }
  .join-content {
    margin-top: 40px; }
    .join-content h3 {
      font-size: 20px;
      line-height: 32px; }
  .how-works-area {
    padding-bottom: 30px; }
  .single-box {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px; }
    .single-box.with-line::before {
      display: none; }
    .single-box span {
      width: 65px;
      height: 65px;
      line-height: 70px;
      font-size: 25px; }
    .single-box h3 {
      font-size: 20px; }
  .our-team .title {
    font-size: 20px; }
  .testimonials-area {
    padding-bottom: 30px; }
    .testimonials-area .section-title {
      position: relative; }
  .testimonials-item {
    padding: 20px; }
    .testimonials-item .client-info .client-title h4 {
      font-size: 18px; }
  .faq-img {
    margin-top: 40px; }
  .single-team .title {
    font-size: 20px; }
  .prices-area {
    padding-bottom: 30px; }
  .pricesTable {
    padding-bottom: 20px;
    margin-bottom: 30px; }
    .pricesTable .title {
      padding: 20px 20px 153px;
      margin: 0 0 30px 0;
      font-size: 20px; }
    .pricesTable .price-value {
      width: 115px;
      height: 115px;
      line-height: 60px;
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.3);
      padding: 18px 0;
      font-size: 24px;
      top: 80px; }
  .prices-table {
    margin-bottom: 30px; }
    .prices-table .title {
      font-size: 20px; }
  .react-sanfona-item-body-wrapper p {
    margin-top: 15px; }
  .newsletter {
    max-width: unset;
    padding: 20px; }
  .newsletter h4 {
    font-size: 20px;
    line-height: 29px; }
  .newsletter form .form-control {
    height: 60px;
    border-radius: 0;
    padding-left: 16px;
    font-size: 16px; }
  .newsletter form .btn {
    position: relative;
    right: 0;
    border-radius: 6px;
    top: 0;
    height: 50px;
    width: 100%;
    margin-top: 20px; }
  .page-title {
    height: 250px; }
    .page-title .page-title-content h3 {
      font-size: 22px; }
  .contact-box .content h4 {
    font-size: 18px; }
  .contact-text h3 {
    font-size: 20px; }
  #contactForm {
    margin-top: 30px; }
  .footer-area {
    padding-bottom: 0; }
  .single-footer {
    margin-bottom: 35px; }
    .single-footer h3 {
      font-size: 20px; }
    .single-footer ul li a {
      font-size: 16px; }
  .copyright-area {
    margin-top: 30px;
    padding: 30px 0;
    text-align: center; }
    .copyright-area p {
      margin-top: 0; }
    .copyright-area ul {
      text-align: center;
      margin-top: 15px; }
      .copyright-area ul li a {
        width: 33px;
        height: 33px;
        line-height: 31px;
        font-size: 15px; }
  .top-header .header-social {
    text-align: center;
    margin-bottom: 10px; }
    .top-header .header-social li span {
      font-size: 13px; }
    .top-header .header-social li a {
      font-size: 15px; }
  .top-header .location {
    text-align: center; }
    .top-header .location span {
      font-size: 13px; }
  .repair-main-banner {
    padding-top: 140px;
    padding-bottom: 270px; }
  .repair-banner-content {
    text-align: center; }
    .repair-banner-content h2 {
      line-height: 1.4;
      font-size: 30px;
      margin-bottom: 16px; }
    .repair-banner-content p {
      margin-bottom: 20px;
      font-size: 13px; }
  .banner-video {
    margin-top: 45px; }
    .banner-video a {
      width: 75px;
      height: 75px;
      line-height: 78px;
      font-size: 25px;
      margin-left: 0; }
      .banner-video a::after {
        width: 75px;
        height: 75px; }
  .repair-home-slides.owl-theme .owl-dots {
    left: 0;
    bottom: 28%;
    right: 0; }
  .repair-boxes-area {
    padding-bottom: 0; }
  .repair-about-content ul li {
    width: 100%;
    font-size: 14px; }
  .repair-about-image {
    margin-top: 35px;
    text-align: center;
    padding-bottom: 45px;
    padding-top: 45px; }
    .repair-about-image img:nth-child(2) {
      border: 3px solid #f8fbff;
      width: 255px;
      position: relative; }
    .repair-about-image img:nth-child(4), .repair-about-image img:nth-child(5) {
      display: none; }
  .single-repair-services {
    padding: 20px; }
    .single-repair-services h3 {
      margin-bottom: 18px;
      margin-top: 20px; }
  .repair-services-inner .col-lg-4:nth-child(2) {
    border: none;
    margin-top: 30px;
    margin-bottom: 30px; }
  .single-repair-feedback {
    padding: 20px; }
  .gallery-area {
    padding-bottom: 30px; }
  .gallery-shorting-menu {
    margin-bottom: 30px; }
    .gallery-shorting-menu .filter {
      font-size: 14px;
      margin-bottom: 8px; }
  .single-gallery-item a img {
    width: 100%; }
  .repair-blog-area {
    padding-bottom: 30px; }
  .single-repair-blog-post .blog-image a img {
    width: 100%; }
  .repair-subscribe-area {
    margin-top: -60px;
    margin-bottom: 60px; }
  .repair-subscribe-content {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px; }
    .repair-subscribe-content h2 {
      margin-bottom: 15px;
      font-size: 24px; }
    .repair-subscribe-content form .form-group {
      width: 100%;
      padding-right: 0;
      margin-bottom: 15px; }
    .repair-subscribe-content form .btn {
      margin-top: 5px;
      width: 100%;
      padding: 12px 17px; }
  .repair-footer-area {
    padding-top: 60px; }
  .single-footer-widget {
    margin-bottom: 30px; }
    .single-footer-widget h3 {
      margin-bottom: 20px; }
  .repair-copyright-area {
    margin-top: 30px;
    text-align: center; }
    .repair-copyright-area .text-right {
      text-align: center !important; }
    .repair-copyright-area ul {
      margin-top: 15px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-img {
    height: 299.156px; }
  .pt-100 {
    padding-bottom: 80px; }
  .ptb-100 {
    padding-bottom: 80px;
    padding-top: 80px; }
  .mt-100 {
    margin-top: 50px; }
  .btn {
    padding: 10px 24px; }
  .navbar-light .navbar-toggler {
    outline: 0;
    padding: 4px 10px; }
    .navbar-light .navbar-toggler .navbar-toggler-icon {
      width: 1.3em;
      height: 1.3em; }
  .navbar-light .navbar-nav {
    margin-top: 10px; }
    .navbar-light .navbar-nav .nav-link {
      padding-left: 0;
      padding-right: 0; }
    .navbar-light .navbar-nav .dropdown .dropdown-menu {
      position: absolute; }
  .main-banner {
    height: 100%;
    padding-top: 80px; }
  .main-banner-content {
    margin-top: 0; }
    .main-banner-content h1 {
      font-size: 36px; }
    .main-banner-content .btn-primary {
      margin-right: 3px; }
  .main-banner-form h3 {
    font-size: 20px;
    padding: 20px 0; }
  .banner-form {
    padding: 20px 15px; }
  .hero-form {
    padding: 20px 15px;
    border-width: 3px; }
  .skills-area {
    padding-bottom: 50px; }
  .single-features {
    margin-bottom: 30px; }
  .about-img {
    text-align: center;
    margin-top: 45px; }
  .profile-area .img {
    margin-top: 90px; }
  .services-area {
    padding-bottom: 50px; }
  .tab .tab_content .react-tabs .react-tabs__tab-list {
    margin-bottom: 30px;
    padding-bottom: 10px; }
    .tab .tab_content .react-tabs .react-tabs__tab-list li {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
      padding-right: 0;
      padding-left: 0;
      margin-bottom: 25px; }
  .fun-fact {
    padding-left: 65px;
    margin-bottom: 30px; }
  .col-lg-3:nth-child(3) .fun-fact,
  .col-lg-3:nth-child(4) .fun-fact {
    margin-bottom: 0; }
  .fun-fact i {
    font-size: 25px;
    width: 50px;
    height: 50px;
    line-height: 50px; }
  .fun-fact h3 {
    font-size: 30px; }
  .fun-fact p {
    font-size: 18px; }
  .join-content {
    margin-top: 0; }
    .join-content h3 {
      font-size: 26px; }
  .how-works-area {
    padding-bottom: 50px; }
  .single-box {
    margin-bottom: 30px; }
    .single-box.with-line::before {
      display: none; }
  .testimonials-area {
    padding-bottom: 50px; }
  .faq-img {
    text-align: center;
    margin-top: 45px; }
  .prices-area {
    padding-bottom: 50px; }
  .pricesTable {
    margin-bottom: 30px; }
  .prices-table {
    margin-bottom: 30px; }
  .page-title {
    height: 300px; }
  .footer-area {
    padding-bottom: 0; }
  .single-footer {
    margin-bottom: 30px; }
  .copyright-area {
    margin-top: 50px; }
  .repair-main-banner {
    padding-top: 160px;
    padding-bottom: 270px; }
  .repair-banner-content {
    text-align: center; }
    .repair-banner-content h2 {
      line-height: 1.2;
      font-size: 40px;
      margin-bottom: 20px; }
    .repair-banner-content p {
      font-size: 17px;
      margin: 0 auto 25px; }
  .repair-home-slides.owl-theme .owl-dots {
    left: 0;
    bottom: 24%;
    right: 0; }
  .banner-video {
    margin-top: 50px; }
    .banner-video a {
      margin-left: -0; }
  .repair-boxes-area {
    padding-bottom: 0; }
  .single-repair-box::before, .single-repair-box::after {
    display: none; }
  .repair-about-image {
    text-align: center;
    margin-top: 35px; }
    .repair-about-image img:nth-child(2) {
      width: 350px;
      position: relative; }
    .repair-about-image img:nth-child(4), .repair-about-image img:nth-child(5) {
      display: none; }
  .repair-services-inner .col-lg-4:nth-child(2) {
    border: none;
    margin-top: 30px;
    margin-bottom: 30px; }
  .single-repair-feedback {
    padding: 20px; }
  .gallery-area {
    padding-bottom: 50px; }
  .repair-blog-area {
    padding-bottom: 50px; }
  .repair-subscribe-content form .form-group {
    width: 33%;
    padding-right: 15px; }
  .repair-subscribe-content form .btn {
    width: 21%;
    margin-top: 20px; }
  .repair-subscribe-area {
    margin-top: -80px;
    margin-bottom: 80px; }
  .repair-footer-area {
    padding-top: 80px; }
  .single-footer-widget {
    margin-bottom: 30px; }
  .repair-copyright-area {
    margin-top: 50px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-img {
    height: 498.61px;
    margin-top: 40px; }
  .main-banner-content {
    margin-top: 0; }
  .single-features {
    padding: 20px; }
  .tab .tab_content .react-tabs .react-tabs__tab-list li {
    font-size: 15px; }
  .join-content {
    margin-top: 35px; }
  .testimonials-item {
    padding: 20px; }
  .repair-home-slides.owl-theme .owl-dots {
    left: 4.5%; } }

@media only screen and (min-width: 1550px) {
  .repair-home-slides.owl-theme .owl-dots {
    left: 21.1%;
    bottom: 28%; } }

