/*
@File: Axolot Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

*** - Default CSS
*** - Prealoader CSS 
*** - Navbar CSS
*** - Main Banner CSS
*** - Features CSS
*** - About CSS
*** - Services CSS
*** - Vision CSS
*** - Fun Facts CSS
*** - Join Now CSS
*** - How It Works CSS
*** - Team CSS
*** - Testimonials CSS
*** - FAQ CSS
*** - prices CSS
*** - Partner CSS
*** - Subscribe CSS
*** - Page Title CSS
*** - Contact CSS
*** - Footer Area CSS
*** - Go Top CSS
*** - Router Transitions
*/

/* ==============
    Start Default CSS 
==================================== */
$font-stack: "Fira Sans", sans-serif;
$font-size: 15px;
$dark-color: #222222;
$pcolor: #6f8ba4;
$white-color: #ffffff;
$theme-color: #506cea;
$secondary-color: #20c997;
$orange-color: #47d167;
$repair-main-color: #4457c3;

.text-theme-color {
  color: $theme-color;
}

$family: unquote(
  "Fira+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i"
);
@import url("https://fonts.googleapis.com/css?family=#{$family}");
body {
  padding: 0;
  margin: 0;
  font: {
    family: $font-stack;
    size: $font-size;
  }
}
img {
  max-width: 100%;
}
.d-table {
  width: 100%;
  height: 100%;

  &-cell {
    vertical-align: middle;
  }
}
a {
  text-decoration: none;
  color: $theme-color;
  transition: 0.4s;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark-color;
}
p {
  margin-bottom: 0;
  font-size: $font-size;
  line-height: 1.8;
  color: $pcolor;
}
.pt-100 {
  padding: {
    top: 100px;
  }
}
.ptb-100 {
  padding: {
    top: 100px;
    bottom: 100px;
  }
}
.ptb-50 {
  padding: {
    top: 50px;
    bottom: 50px;
  }
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-100 {
  margin-top: 100px;
}
.bg-gray {
  background-color: #edf5ff;
}
/* Section Title */
.section-title {
  margin-bottom: 60px;
  text-align: center;
  margin-top: -6px;

  span {
    display: block;
    font-size: 16px;
    margin-bottom: 6px;
    color: $theme-color;
  }
  h3 {
    font-size: 30px;
    margin: {
      bottom: 20px;
      left: auto;
      right: auto;
    }
    position: relative;
    padding-bottom: 20px;
    max-width: 520px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 75px;
      height: 3px;
      border-radius: 5px;
      right: 0;
      margin: 0 auto;
      background: $theme-color;
    }
  }
  p {
    max-width: 550px;
    margin: 0 auto;
  }
}
/* Owl Carousel Btn */
.owl-theme {
  .owl-nav {
    margin-top: 0;

    [class*="owl-"] {
      font-size: 17px !important;
      margin: 0 !important;
      padding: 0 !important;
      background: $white-color !important;
      display: inline-block !important;
      border-radius: 0 !important;
      position: absolute;
      left: 20px;
      color: $theme-color !important;
      top: 22%;
      height: 150px;
      opacity: 0;
      overflow: hidden;
      width: 15px;
      line-height: 150px;
      transition: 0.4s;

      &:hover {
        color: $white-color !important;
        background: $theme-color !important;
      }
    }
    .owl-next {
      left: auto;
      right: 20px;
    }
  }
  &:hover {
    .owl-nav {
      [class*="owl-"] {
        opacity: 1;
        overflow: visible;
        left: 0;
      }
      .owl-next {
        left: auto;
        right: 0;
      }
    }
  }
}
/* Btn Button */
.btn {
  font-size: 16px;
  border-radius: 5px;
  padding: 10px 27px;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 200px;
    height: 100px;
    -webkit-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    -ms-transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transform: translate3d(-120%, -50px, 0) rotate3d(0, 0, 1, 45deg);
    transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1);
    background: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
  }
  &:hover {
    &::before {
      background: $white-color;
      -webkit-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
      -ms-transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
      transform: translate3d(120%, -100px, 0) rotate3d(0, 0, 1, 90deg);
    }
  }
}
.btn-primary {
  background-color: $theme-color;
  border-color: $theme-color;
  color: $white-color;

  &.focus,
  &:focus {
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: $white-color;
    background-color: $theme-color;
    border-color: $theme-color;
  }
  &:hover {
    color: $white-color;
    background-color: $theme-color;
    border-color: $theme-color;
  }
  &.disabled,
  &:disabled {
    opacity: 1;
    background-color: $theme-color;
    border-color: $theme-color;
  }
}
.btn-secondary {
  color: $white-color;
  background-color: $secondary-color;
  border-color: $secondary-color;

  &:hover {
    color: $white-color;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
  }
  &.focus,
  &:focus {
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active,
  .show > .btn-secondary.dropdown-toggle {
    color: $white-color;
    background-color: $secondary-color;
    border-color: $secondary-color;
  }
}
/* ==============
    End Default CSS 
==================================== */

/* ==============
    Start Navbar CSS 
==================================== */
.navbar {
  padding: 20px 0;

  img {
    opacity: 0;
    transition: 0.2s linear all;
  }

  &.is-sticky {
    background: $white-color;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.4);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;

    img {
      opacity: 1;
    }
  }
}
.bg-light {
  background-color: $white-color !important;
  z-index: 99;

  .navbar-toggler {
    border-color: $theme-color;

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(88, 130, 253, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
  }
  .navbar-brand {
    color: $dark-color;
    text-transform: uppercase;
    cursor: pointer;
    font: {
      size: 25px;
      weight: 700;
    }
    span {
      color: $theme-color;
    }
  }
  .navbar-nav {
    .nav-link {
      color: #333333;
      font-size: 16px;
      cursor: pointer;
      position: relative;
      padding: {
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
      }
      margin: {
        left: 15px;
        right: 15px;
      }
      &:focus,
      &:hover {
        color: $theme-color;

        &::before {
          width: 100%;
        }
      }
      &:last-child {
        padding-right: 0;
      }
      &.active,
      &.show {
        color: $theme-color;
      }
      &.active,
      &.show > {
        .nav-link {
          color: $theme-color;

          &::before {
            width: 100%;
          }
        }
      }
      &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        transition: 0.4s;
        background: $theme-color;
      }
    }
    .dropdown {
      .dropdown-menu {
        min-width: 15rem;
        padding: 15px 15px;
        margin: 0;
        top: 115%;
        background-color: $white-color;
        box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
        border: none;
        border-radius: 10px;
        display: inline-block;
        opacity: 0;
        visibility: hidden;
        transition-duration: 0.4s;

        .dropdown-item {
          padding: 0;
          margin: 15px 0;
          background: transparent;
          color: #333333;

          &:hover,
          &.active {
            color: $theme-color;
          }
        }
      }
      &:hover {
        .dropdown-menu {
          top: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
/* ==============
    End Navbar CSS 
==================================== */

/* ==============
    Start Main Banner CSS 
==================================== */
.main-banner {
  height: 680px;
  position: relative;
}
.main-banner-content {
  text-align: left;
  margin-top: 50px;
  position: relative;
  z-index: 2;

  h1 {
    font: {
      size: 55px;
      weight: 500;
    }
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
  }
  .btn-primary {
    margin-right: 13px;
  }
  .btn-secondary {
    i {
      margin-right: 4px;
    }
  }
}
.banner-img {
  height: 598.328px;
  text-align: center;
}
.creative-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  background: $theme-color;
  height: 100%;
  border-radius: 100% 0 0 0;
}
.creative-bg-two {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  background: $theme-color;
  height: 100%;
  border-radius: 0 0 0 100%;
}

.main-banner-form {
  position: relative;
  z-index: 1;
  background: $white-color;
  padding: 0 0 35px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

  h3 {
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 35px;
    background: $secondary-color;
    color: $white-color;
    border-radius: 2px;
    padding: 25px 0;
  }
  form {
    padding: {
      left: 25px;
      right: 25px;
    }
    .form-control {
      border: 1px solid #eeeeee;
      font-size: 14px;
      border-radius: 2px;
    }
    select.form-control {
      height: 50px;
      cursor: pointer;
    }
    .btn {
      margin-top: 10px;
      padding: 12px 27px;
      margin-top: 15px;
      font-size: 16px;
      z-index: 1;
    }
  }
}
.banner-form {
  background: $white-color;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 40px 25px;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    background: $white-color;
    width: 96%;
    opacity: 0.62;
    height: 50%;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 3px;
  }
  form {
    .form-control {
      border: 1px solid #eeeeee;
      font-size: 14px;
      border-radius: 2px;
    }
    label {
      color: $pcolor;
      margin-bottom: 8px;
      font-size: 15px;
    }
    select.form-control {
      height: 50px;
      cursor: pointer;
    }
    .btn {
      margin-top: 10px;
    }
  }
}
.hero-form {
  background: $white-color;
  padding: 40px 25px;
  position: relative;
  z-index: 1;
  border: 5px solid $secondary-color;

  form {
    .form-control {
      border: 1px solid #eeeeee;
      font-size: 14px;
      border-radius: 2px;
    }
    label {
      color: $pcolor;
      margin-bottom: 8px;
      font-size: 15px;
    }
    select.form-control {
      height: 50px;
      cursor: pointer;
    }
    .btn {
      display: block;
      width: 100%;
      margin-top: 25px;

      &::before,
      &::after {
        display: none;
      }
    }
  }
}
/* ==============
    End Main Banner CSS 
==================================== */

/* ==============
    Start Features CSS 
==================================== */
.skills-area {
  padding-bottom: 70px;

  .section-title {
    a,
    a:hover {
      color: $theme-color;
      cursor: pointer;
      font-weight: 500;
    }
  }
}
.single-features {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: $white-color;
  padding: 30px;
  border-radius: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  i {
    font-size: 40px;
    display: inline-block;
    -moz-transition: 0.4s;
    color: $theme-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  h3 {
    font-size: 20px;
    margin: {
      top: 20px;
      bottom: 20px;
    }
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  p {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &:hover {
    background: $theme-color;

    h3,
    p,
    i {
      color: $white-color;
    }
  }
}
/* ==============
    End Features CSS 
==================================== */

/* ==============
    Start About CSS 
==================================== */
.profile-area {
  position: relative;
}
.about-content {
  h3 {
    font-size: 26px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  h4 {
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 25px;
  }
  .pull-left {
    float: left;
    margin-right: 45px !important;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      color: $pcolor;
      margin-top: 10px;
      font-weight: 400;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }
      i {
        margin-right: 4px;
        color: $theme-color;
      }
    }
  }
  .btn {
    margin-top: 25px;
  }
}
.about-text {
  span {
    display: inline-block;
    font: {
      size: 20px;
      weight: 900;
    }
    color: $white-color;
    width: 50px;
    height: 50px;
    background: $theme-color;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
  }
  h3 {
    font-size: 26px;
    margin: {
      bottom: 15px;
      top: 25px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    margin-top: 15px;

    li {
      color: $pcolor;
      margin-top: 10px;
      font-weight: 400;

      i {
        margin-right: 4px;
        color: $theme-color;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
  .blockquote {
    margin-bottom: 0;
    background: $theme-color;
    padding: 15px;
    margin-top: 15px;
    border-radius: 5px;

    p {
      color: $white-color;
    }
  }
}
/* ==============
    End About CSS 
==================================== */

/* ==============
    Start Services CSS 
==================================== */
.services-area {
  padding-bottom: 70px;
}
.single-skills {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  background: $white-color;
  border-radius: 5px;
  height: 100%;
  padding: 30px;
  cursor: pointer;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  .rate {
    color: $theme-color;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 0.9rem;

    &.is-negative {
      opacity: 0.5;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      color: $pcolor;
      margin-top: 10px;
      font-weight: 400;
      text-align: left;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  h3 {
    font-size: 20px;
    margin: {
      top: 25px;
      bottom: 20px;
    }
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  p {
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &:hover {
    background: $theme-color;

    .rate {
      color: $white-color;
    }
    h3,
    p,
    li {
      color: $white-color;
    }
  }
}
/* ==============
    End Services CSS 
==================================== */

/* ==============
    Start Vision CSS 
==================================== */
.tab {
  overflow: hidden;

  .tab_content {
    .react-tabs {
      .react-tabs__tab-list {
        list-style-type: none;
        margin-bottom: 40px !important;
        padding-left: 0 !important;
        border-bottom: 2px solid #edf5ff !important;
        padding-bottom: 35px !important;
        display: -ms-flexbox !important;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        li {
          -ms-flex: 0 0 16.6666666667%;
          flex: 0 0 16.6666666667%;
          max-width: 16.6666666667%;
          padding: {
            right: 15px;
            left: 15px;
          }
          text-align: center;
          color: $dark-color;
          font: {
            size: 18px;
            weight: 500;
          }
          text-decoration: none;
          display: inline-block;
          border: none;
          bottom: 0;
          box-shadow: unset !important;
          outline: 0 !important;
          padding: 0;
          -moz-transition: 0.4s;
          -webkit-transition: 0.4s;
          transition: 0.4s;

          &:hover {
            color: $theme-color;

            i {
              color: $white-color;
              background: $theme-color;
            }
          }
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
          i {
            display: inline-block;
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: #edf5ff;
            text-align: center;
            font-size: 30px;
            border-radius: 50%;
            margin-bottom: 15px;
            -moz-transition: 0.4s;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
        }
        .react-tabs__tab--selected {
          color: $theme-color;

          i {
            color: $white-color;
            background: $theme-color;
          }
        }
      }
      .tabs_item {
        display: none;

        &:first-child {
          display: block;
        }
        .tabs_item_content {
          h3 {
            font-size: 22px;
            margin-bottom: 15px;
          }
          ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            margin-top: 15px;

            li {
              color: #6f8ba4;
              margin-top: 10px;
              font-weight: 400;

              &:first-child {
                margin-top: 0;
              }
              i {
                margin-right: 4px;
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}
/* ==============
    End Vision CSS 
==================================== */

/* ==============
    Start Fun Facts CSS 
==================================== */
.funfacts-area {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: $theme-color;
    left: 0;
    opacity: 0.84;
  }
}
.funFact {
  text-align: center;
  padding: {
    top: 100px;
    bottom: 100px;
  }
  position: relative;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 75px;
    background: $white-color;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &::after {
    top: auto;
    bottom: 0;
  }
  i {
    font-size: 28px;
    background: $white-color;
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 1px solid $white-color;
    line-height: 61px;
    color: $theme-color;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  h3 {
    font: {
      size: 40px;
      weight: 700;
    }
    color: $white-color;
    margin: {
      top: 20px;
      bottom: 10px;
    }
  }
  p {
    color: $white-color;
    font: {
      size: 20px;
      weight: 300;
    }
    line-height: 22px;
    margin-bottom: 0;
  }
  &:hover {
    &::before,
    &::after {
      height: 0;
    }
    i {
      color: $white-color;
      background: transparent;
    }
  }
}
/* Fun Fact Two */
.fun-fact {
  position: relative;
  padding-left: 80px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  i {
    font-size: 28px;
    background: $white-color;
    display: inline-block;
    width: 60px;
    position: absolute;
    color: $theme-color;
    left: 0;
    top: 0;
    height: 60px;
    border: 1px solid $white-color;
    line-height: 61px;
    text-align: center;
    border-radius: 50%;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  h3 {
    font: {
      size: 40px;
      weight: 700;
    }
    color: $white-color;
    margin-bottom: 10px;
  }
  p {
    color: $white-color;
    font: {
      size: 20px;
      weight: 300;
    }
    line-height: 22px;
    margin-bottom: 0;
  }
  &:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);

    i {
      color: $white-color;
      background: transparent;
    }
  }
}
/* ==============
    End Fun Facts CSS 
==================================== */

/* ==============
    Start Join Now CSS 
==================================== */
.join-content {
  margin-top: 45px;

  h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 25px;
  }
  .btn-primary {
    margin-right: 6px;
  }
}
/* ==============
    End Join Now CSS 
==================================== */

/* ==============
    Start How It Works CSS 
==================================== */
.single-box {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  span {
    display: inline-block;
    width: 80px;
    height: 80px;
    line-height: 84px;
    text-align: center;
    font-size: 30px;
    margin-bottom: 25px;
    background: #edf5ff;
    border-radius: 50%;
    font-weight: 700;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  &:hover {
    span {
      color: $white-color;
      background: $theme-color;
    }
  }
}
/* ==============
    End How It Works CSS 
==================================== */

/* ==============
    Start Team CSS 
==================================== */
.our-team {
  text-align: center;
  position: relative;

  .pic {
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(53, 53, 53, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 1;
      transform: scale(1);
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  img {
    border-radius: 50%;
    transform: scale(1);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .social {
    width: 100%;
    position: absolute;
    top: 45%;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 2;
    list-style-type: none;
    opacity: 0;
    transform: scale(1.2);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    li {
      display: inline-block;

      a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        color: $theme-color;
        margin: 0 3px;
        background: $white-color;
        text-decoration: none;

        &:hover {
          color: $white-color;
          background: $theme-color;
        }
      }
    }
  }
  .title {
    font: {
      size: 22px;
      weight: 700;
    }
    color: $dark-color;
    text-transform: capitalize;
    margin: {
      top: 25px;
      bottom: 10px;
    }
  }
  .post {
    display: inline-block;
    color: $pcolor;
  }
  &:hover {
    .pic {
      &::before {
        opacity: 1;
        transform: scale(0.95);
        visibility: visible;
      }
    }
    img {
      transform: scale(1.3);
    }
    .social {
      opacity: 1;
      transform: scale(1);
    }
  }
}
/* Team Style Two */
.single-team {
  text-align: center;

  .pic {
    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.55);
      opacity: 0;
      z-index: 1;
      border: 2px solid $theme-color;
      visibility: hidden;
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
      z-index: 2;
      visibility: hidden;
      -moz-transition: 0.4s;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .social-links {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 40px;
    left: 0;
    opacity: 0;
    z-index: 6;
    visibility: hidden;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    li {
      display: inline-block;

      a {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        line-height: 30px;
        border-radius: 50%;
        font-size: 15px;
        color: $white-color;
        border: 1px solid $white-color;
        text-decoration: none;

        &:hover {
          background: $theme-color;
          border-color: $theme-color;
        }
      }
    }
  }
  .team-content {
    padding: 20px 0;
    background: #edf5ff;
    border-top: 2px solid $theme-color;

    .title {
      font: {
        size: 22px;
        weight: 700;
      }
      color: $dark-color;
      text-transform: capitalize;
      margin-bottom: 10px;
      position: relative;
    }
    .post {
      display: inline-block;
      color: $pcolor;
      position: relative;
    }
  }
  &:hover {
    .pic {
      &::before,
      &::after {
        opacity: 1;
        visibility: visible;
      }
      &::before {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
      &::after {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
      }
    }
    .social-links {
      opacity: 1;
      visibility: visible;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
/* ==============
    End Team CSS 
==================================== */

/* ==============
    Start Testimonials CSS 
==================================== */
.testimonials-area {
  padding-bottom: 70px;
  position: relative;

  .section-title sup {
    color: $theme-color;
    font-size: 1.2rem;
    margin-left: 5px;
  }

  .source {
    color: $theme-color;
    font-size: 0.9rem;
  }
}
.testimonials-item {
  -webkit-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 30px;
  position: relative;
  background: $white-color;
  border-radius: 5px;
  margin-bottom: 30px;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:hover {
    .more,
    .less {
      color: lighten($theme-color, 20%);
    }
  }

  .client-info {
    position: relative;
    padding-left: 110px;
    margin-bottom: 40px;

    .img {
      position: absolute;
      left: 0;
      top: 0;

      img {
        border-radius: 50%;
      }
    }
    .client-title {
      display: inline-block;
      margin-top: 14px;

      h4 {
        font-size: 20px;
        margin-bottom: 8px;
        text-transform: uppercase;
      }
      span {
        display: inline-block;
        color: $theme-color;
      }
      span,
      h4 {
        position: relative;
        z-index: 1;
        -moz-transition: 0.4s;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }
    }
  }
  p {
    position: relative;
    z-index: 1;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  i {
    position: absolute;
    right: 28px;
    bottom: 20px;
    font-size: 98px;
    color: #edf5ff;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  &:hover {
    background: $theme-color;

    .client-info {
      .client-title {
        span,
        h4 {
          color: $white-color;
        }
      }
    }
    p {
      color: $white-color;
    }
    i {
      color: #636363;
    }
  }
}
/* ==============
    End Testimonials CSS 
==================================== */

/* ==============
    Start FAQ CSS 
==================================== */
.accordion {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: none !important;

  .accordion-item {
    display: block;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    margin-top: 10px;
  }
  .accordion-title {
    display: block;
    color: $white-color !important;
    padding: 10px 20px;
    border-radius: 30px;
    text-decoration: none;
    background: $theme-color;

    &:hover {
      background-color: $secondary-color;
    }
  }
  .active {
    .accordion-title {
      background: $secondary-color;
      border-radius: 0;
    }
  }
  .accordion-content {
    display: none;
    position: relative;
    overflow: hidden;
    padding: 15px 15px 0;
  }
  .accordion-content {
    &.show {
      display: block;
    }
  }
  .accordion__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .accordion__button {
    background-color: $theme-color;
    color: $white-color;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    text-align: left;
    border: none;
    outline: 0;
    border-radius: 30px;
    transition: 0.5s;

    &:hover {
      background-color: $secondary-color;
    }
  }
  .accordion__panel {
    p {
      margin-top: 0;
    }
  }
  .accordion__item + .accordion__item {
    border: none;
  }
}
/* ==============
    End FAQ CSS 
==================================== */

/* ==============
    Start Prices CSS 
==================================== */
.pricesTable {
  padding-bottom: 30px;
  background: $white-color;
  border-radius: 5px;
  text-align: center;
  position: relative;
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  i {
    color: $theme-color;
    font-size: 1.3rem;
    vertical-align: middle;
  }

  .title {
    padding: 30px 20px 192px;
    margin: 0 0 30px 0;
    font-size: 25px;
    color: $white-color;
    overflow: hidden;
    position: relative;
    background: $theme-color;

    &::before,
    &::after {
      content: "";
      width: 280px;
      height: 200px;
      border-radius: 80px;
      background: $white-color;
      position: absolute;
      bottom: -175px;
      left: -46px;
      transform: rotate(-85deg);
    }
    &::after {
      border-radius: 100px;
      bottom: auto;
      top: 150px;
      left: auto;
      right: -70px;
      transform: rotate(-40deg);
    }
  }
  .price-value {
    display: inline-block;
    width: 140px;
    height: 140px;
    line-height: 65px;
    border-radius: 50%;
    background: $white-color;
    -webkit-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 30px 0;
    font: {
      size: 30px;
      weight: 600;
    }
    position: absolute;
    top: 100px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .month {
    display: block;
    font: {
      size: 16px;
      weight: normal;
    }
    line-height: 0;
  }
  .prices-content {
    list-style: none;
    padding: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 25px;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    li {
      margin-bottom: 12px;
      color: #737373;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &:hover {
    .price-value {
      color: $white-color;
      background: $theme-color;
    }
  }
}
/* prices Style Two */
.prices-table {
  -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
  padding: 50px 0 30px;
  text-align: center;
  margin-top: 33px;
  border-radius: 5px;
  position: relative;
  background: $white-color;
  cursor: pointer;

  .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    border-radius: 50%;
    background: $white-color;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    margin: 0 auto;
    font-size: 25px;
    position: absolute;
    color: $theme-color;
    top: -34px;
    left: 0;
    right: 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .prices-table-header {
    margin: {
      bottom: 25px;
      top: 20px;
    }
    padding: 20px 0;
    background: $theme-color;
  }
  .title {
    font: {
      size: 22px;
      weight: 600;
    }
    text-transform: uppercase;
    margin: 0 0 15px 0;
    color: $white-color;
  }
  .price-value {
    display: inline-block;
    font: {
      size: 30px;
      weight: 700;
    }
    color: $white-color;
    border-bottom: 3px solid $white-color;

    b {
      font: {
        weight: 500;
        size: 15px;
      }
    }
  }
  .prices-content {
    list-style: none;
    padding: 0;
    margin: 0 0 25px 0;

    li {
      margin-bottom: 12px;
      color: #737373;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &:hover {
    .icon {
      color: $white-color;
      background: $theme-color;
    }
  }
}
/* ==============
    End prices CSS 
==================================== */

/* ==============
    Start Partner CSS 
==================================== */
.partner-area {
  .item {
    text-align: center;
  }
  .owl-item {
    img {
      border: 1px solid $theme-color;
      display: inline-block;
    }
  }
}
/* ==============
    End Partner CSS 
==================================== */

/* ==============
    End Subscribe CSS 
==================================== */
.subscribe-area {
  text-align: center;
  position: relative;
  background: $theme-color;
}
.newsletter {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: $white-color;
  padding: 35px;
  border-radius: 5px;

  h4 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 30px;

    span {
      text-transform: uppercase;
      font-weight: 600;
      color: $theme-color;
    }
  }
  .subscribe-form {
    position: relative;

    .form-control {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
      border: none;
      height: 75px;
      border-radius: 50px;
      padding-left: 20px;
      font-size: 17px;
    }
    .btn {
      position: absolute;
      right: 7px;
      border-radius: 30px;
      top: 8px;
      height: 60px;
      text-transform: uppercase;
      width: 175px;
    }
    .validation-danger {
      margin-top: 15px;
      color: red;
    }
    .validation-success {
      margin-top: 15px;
    }
  }
}
.subs-sending-msg {
  color: black;
}
.subs-sending-msg {
  color: #6993ff;
}
.subs-error-msg {
  color: #ff7344;
}
/* ==============
    End Subscribe CSS 
==================================== */

/* ==============
    Start Page Title CSS 
==================================== */
.page-title {
  position: relative;
  height: 350px;
  background: $theme-color;

  .page-title-content {
    text-align: center;
    position: relative;
    margin-top: -50px;

    h3 {
      font-size: 25px;
      text-transform: uppercase;
      margin-bottom: 20px;
      color: $white-color;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        display: inline-block;
        color: #eeeeee;
        font-size: 16px;

        a {
          display: inline-block;
          color: $white-color;
          text-decoration: none;
        }
      }
    }
  }
}
/* ==============
    End Page Title CSS 
==================================== */

/* ==============
    Start Contact CSS 
==================================== */
.contact-box {
  background: $white-color;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.06);
  padding: 20px 20px 20px 90px;
  border-radius: 5px;
  position: relative;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  .icon {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    border: 1px dashed $theme-color;
    color: $theme-color;
    border-radius: 50%;
    font-size: 22px;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .content {
    h4 {
      font-size: 20px;
      margin-bottom: 12px;
    }
    p {
      a {
        color: #777777;
        text-decoration: none;

        &:hover {
          color: $theme-color;
        }
      }
    }
  }
  &:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);

    .icon {
      color: $white-color;
      background: $theme-color;
    }
  }
}
#map {
  width: 100%;
  height: 400px;
  margin: {
    top: 40px;
    bottom: 40px;
  }
  position: inherit;
}
.googleMap {
  margin: 40px 0;
}
.contact-text {
  h3 {
    font-size: 22px;
    margin-bottom: 0;
  }
  p {
    margin-top: 20px;
  }
}
.form-control {
  height: 45px;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-weight: 300;
  font-size: 16px;
  box-shadow: unset !important;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  &:focus {
    box-shadow: unset;
    padding-left: 20px;
  }
}
#contactForm {
  .list-unstyled {
    li {
      color: red;
    }
  }
  .btn {
    text-transform: uppercase;
    padding: 10px 35px;
    margin-top: 15px;
    font: {
      weight: 500;
      size: 18px;
    }
    z-index: 1;
  }
  .contactMsg {
    color: $theme-color;
  }
}
/* ==============
    End Contact CSS 
==================================== */

/* ==============
    Start Footer CSS 
==================================== */
.footer-area {
  padding-bottom: 0;
}
.single-footer {
  text-align: left;

  .logo {
    margin: {
      top: -6px;
      bottom: 15px;
    }
    a {
      color: $dark-color;
      text-transform: uppercase;
      font: {
        size: 25px;
        weight: 700;
      }
      text-decoration: none;

      span {
        color: $theme-color;
      }
    }
  }
  .btn {
    margin-top: 15px;
  }
  h3 {
    font-size: 24px;
    margin: {
      top: -5px;
      bottom: 25px;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        color: #6f8ba4;
        margin-top: 8px;
        display: block;
        text-decoration: none;
        font-size: 17px;

        i {
          margin-right: 4px;
        }
        &:hover {
          padding-left: 7px;
          color: $theme-color;
        }
      }
      &:first-child {
        a {
          margin-top: 0;
        }
      }
    }
  }
  .contact-info {
    margin-top: 15px;

    li {
      margin-bottom: 10px;
      color: #737373;
      position: relative;
      padding-left: 25px;

      &:last-child {
        margin-bottom: 0;
      }
      i {
        position: absolute;
        left: 0;
        top: 3px;
        color: $theme-color;
        font-size: 20px;
      }
    }
  }
}
.copyright-area {
  margin-top: 100px;
  background: #edf5ff;
  padding: 40px 0;

  p {
    color: $dark-color;
    margin-top: 6px;
  }
  ul {
    text-align: right;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      a {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 33px;
        text-align: center;
        margin-left: 4px;
        border-radius: 50%;
        border: 1px solid $theme-color;
        text-decoration: none;

        &:hover {
          color: $white-color;
          background: $theme-color;
          border-color: $theme-color;
        }
      }
    }
  }
}
/* ==============
    End Footer CSS 
==================================== */

/* ==============
    Scroll Top
==================================== */
aside {
  &.ScrollUpButton__Container {
    &.ScrollUpButton__Toggled {
      &:hover {
        background-color: $theme-color !important;
        transition: 1s !important;
        outline: 0 !important;
      }
    }
  }
}

/* ==============
    Router Transitions
==================================== */
.fade-enter {
  opacity: 0.01;

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
}
.fade-exit {
  opacity: 1;

  &.fade-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
}
div {
  &.transition-group {
    position: relative;
  }
}
section {
  &.route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
/* ==============
    Start Repair Main Banner CSS 
==================================== */
.repair-main-banner {
  background: {
    position: center center;
    size: cover;
    repeat: no-repeat;
  }
  position: relative;
  z-index: 1;
  padding: {
    top: 200px;
    bottom: 300px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: -1;
    opacity: 0.6;
  }
}
.repair-banner-content {
  h2 {
    color: $white-color;
    line-height: 1.2;
    font: {
      size: 55px;
      weight: 700;
    }
    margin-bottom: 25px;
  }
  p {
    max-width: 550px;
    margin-bottom: 25px;
    color: $white-color;
    font-size: 17px;
  }
  .btn {
    text-transform: uppercase;
  }
}
.banner-video {
  a {
    width: 100px;
    display: inline-block;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    text-align: center;
    background-color: $white-color;
    border-radius: 50%;
    z-index: 1;
    color: $repair-main-color;
    margin-left: -40px;
    position: relative;
    transition: 0.5s;

    &::after {
      z-index: -1;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
      height: 100px;
      animation: ripple 1.6s ease-out infinite;
      opacity: 0;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
    }
    &:hover,
    &:focus {
      background-color: $repair-main-color;
      color: $white-color;
    }
  }
}
.repair-home-slides {
  &.owl-theme {
    .owl-dots {
      line-height: 0.1;
      margin-top: 0;
      position: absolute;
      left: 9.5%;
      bottom: 28%;

      .owl-dot {
        span {
          width: 15px;
          height: 4px;
          margin: 0 3px;
          background: #d5d6d6;
          transition: 0.4s;
          border-radius: 0;
        }
        &:hover,
        &:focus {
          span {
            background: $white-color;
          }
        }
        &.active {
          span {
            width: 30px;
            background: $white-color;
          }
        }
      }
    }
  }
}
@keyframes ripple {
  0%,
  35% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    transform: scale(2);
  }
}
/* ==============
    End Repair Main Banner CSS 
==================================== */

/* ==============
    Start Repair Boxes CSS 
==================================== */
.repair-boxes-area {
  background-color: transparent;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: {
    top: -150px;
    bottom: -45px;
  }
  padding: {
    top: 40px;
    bottom: 40px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    height: 90%;
    bottom: 0;
    width: 201%;
    background: $white-color;
    z-index: -1;
    transform: skewY(177deg);
  }
}
.single-repair-box {
  text-align: center;
  border-radius: 5px;
  background-color: $white-color;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  padding: 45px 25px;
  margin-bottom: 45px;

  i {
    font-size: 50px;
    color: $orange-color;
    transition: 0.5s;
  }
  h3 {
    text-transform: uppercase;
    font: {
      size: 18px;
      weight: 600;
    }
    margin: {
      top: 25px;
      bottom: 15px;
    }
    transition: 0.5s;
  }
  a {
    font-weight: 600;
    margin-top: 20px;
    color: $dark-color;
    display: inline-block;
    transition: 0.5s;

    &:hover,
    &:focus {
      color: $repair-main-color;
      text-decoration: none;
      letter-spacing: 1.5px;
    }
  }
  .img-box {
    img {
      &:first-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        opacity: 0.7;
        transition: 0.5s;
      }
      &:last-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
      }
    }
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -40px;
    left: 0;
    height: 40px;
    width: 100%;
    background: $repair-main-color;
    border-radius: 5px 5px 0 0;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  &::after {
    top: auto;
    bottom: -40px;
    border-radius: 0 0 5px 5px;
  }
  &.active {
    border-radius: 0;
    background-color: $repair-main-color;

    i {
      color: $white-color;
    }
    h3 {
      color: $white-color;
    }
    p {
      color: $white-color;
    }
    a {
      color: $white-color;
    }
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
    }
    .img-box {
      img {
        &:first-child {
          opacity: 0;
          visibility: hidden;
        }
        &:last-child {
          opacity: 0.35;
          visibility: visible;
        }
      }
    }
  }
}
/* ==============
    End Repair Boxes CSS 
==================================== */

/* ==============
    Start Repair About CSS 
==================================== */
.repair-about-content {
  .section-title {
    text-align: left;
    margin-bottom: 25px;

    h3 {
      margin: {
        left: 0;
        right: 0;
      }
      padding-bottom: 0;
      &::before {
        display: none;
      }
    }
    p {
      margin: {
        left: 0;
        right: 0;
      }
    }
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    overflow: hidden;
    margin: {
      bottom: 10px;
      left: -15px;
      right: -15px;
    }
    li {
      float: left;
      width: 50%;
      margin-bottom: 15px;
      padding: {
        left: 15px;
        right: 15px;
      }
      font: {
        size: 17px;
        weight: 600;
      }
      i {
        color: $orange-color;
        margin-right: 3px;
      }
    }
  }
  .read-more-btn {
    display: inline-block;
    margin-top: 20px;
    text-transform: uppercase;
    color: $orange-color;
    font: {
      size: 14px;
      weight: 700;
    }
    &:hover {
      color: $repair-main-color;
      text-decoration: none;
    }
  }
}
.repair-about-image {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    position: absolute;

    &:nth-child(1) {
      left: 0;
      top: 0;
      z-index: 1;
    }
    &:nth-child(2) {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      text-align: center;
      z-index: 2;
      margin: 0 auto;
      border: 5px solid #f8fbff;
      width: 350px;
    }
    &:nth-child(3) {
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    &:nth-child(4) {
      right: 0;
      top: 0;
    }
    &:nth-child(5) {
      left: 0;
      bottom: 0;
    }
  }
}
/* ==============
    End Repair About CSS 
==================================== */

/* ==============
    Start Repair Feedback CSS 
==================================== */
.feedback-area {
  position: relative;
  z-index: 1;
}
.single-repair-feedback {
  background-color: #f8fbff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;

  .client-info {
    margin-bottom: 25px;
    position: relative;
    padding: {
      left: 90px;
      top: 18px;
    }
    img {
      display: inline-block !important;
      width: 70px !important;
      height: 70px;
      position: absolute;
      left: 0;
      top: 0;
    }
    h3 {
      font: {
        size: 18px;
        weight: 700;
      }
      margin-bottom: 5px;
    }
    span {
      display: block;
      color: $repair-main-color;
    }
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin: {
      bottom: 0;
      top: 15px;
    }
    li {
      display: inline-block;
      color: #ff5202;
    }
  }
  .icofont-quote-left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 0.05;
    font-size: 180px;
  }
}
.repair-feedback-slides {
  &.owl-theme {
    .owl-dots {
      line-height: 0.1;
      margin-top: 45px;

      .owl-dot {
        span {
          width: 15px;
          height: 4px;
          margin: 0 3px;
          background: #e7e7e7;
          transition: 0.4s;
          border-radius: 0;
        }
        &:hover,
        &:focus {
          span {
            background: $repair-main-color;
          }
        }
        &.active {
          span {
            width: 30px;
            background: $repair-main-color;
          }
        }
      }
    }
  }
}
.map-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  text-align: center;
  z-index: -1;
}
/* ==============
    End Repair Feedback CSS 
==================================== */

/* ==============
    Start Gallery CSS 
==================================== */
.gallery-area {
  padding-bottom: 70px;

  .section-title {
    margin-bottom: 40px;
  }
}
.single-gallery-item {
  overflow: hidden;
  margin-bottom: 30px;

  a {
    display: block;

    img {
      transition: all 1.5s ease-out;
    }
  }
  &:hover,
  &:focus {
    a {
      img {
        transform: scale(1.4);
      }
    }
  }
}
// .shorting {
//     .mix {
//         // display: none;
//     }
// }
.gallery-shorting-menu {
  display: block;
  text-align: center;
  margin-bottom: 40px;
  padding: 0;
  background-color: transparent;
  border-radius: 0;

  .filter {
    border-radius: 0;
    outline: 0;
    background-color: transparent;
    border: 1px solid #eeeeee;
    color: $dark-color;
    text-transform: capitalize;
    margin: 0 3px;
    padding: 9px 24px;
    -o-transition: 0.4s;
    -ms-transition: 0.4s;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
    font: {
      size: 15px;
      weight: 500;
    }
    &:hover,
    &:focus,
    &.active {
      background-color: $repair-main-color;
      color: $white-color;
      border-color: $repair-main-color;
    }
  }
}
/* ==============
    End Gallery CSS 
==================================== */

/*=============================
Start Repair Partner CSS
===============================*/
.repair-partner-area {
  background-color: #edf5ff;
  text-align: center;
  padding: {
    top: 50px;
    bottom: 50px;
  }
}
.single-repain-client {
  text-align: center;

  img {
    width: auto !important;
    height: auto;
    display: inline-block !important;
  }
}
/* ==============
    End Repair Partner CSS 
==================================== */

/* ==============
    Start Repair Blog CSS 
==================================== */
.repair-blog-area {
  padding-bottom: 70px;
}
.single-repair-blog-post {
  margin-bottom: 30px;

  .blog-image {
    overflow: hidden;
    position: relative;

    a {
      display: block;

      img {
        transition: all 1.5s ease-out;
      }
    }
    .tags {
      position: absolute;
      left: 15px;
      z-index: 2;
      top: 15px;
      transition: 0.4s;

      a {
        background: $orange-color;
        color: $white-color;
        text-transform: uppercase;
        border-radius: 5px;
        padding: 5px 10px 3px;
        text-decoration: none;
        font: {
          weight: 600;
          size: 13px;
        }
      }
    }
  }
  .blog-content {
    margin-top: 20px;

    h3 {
      line-height: 1.4;
      margin-bottom: 0;
      font: {
        size: 20px;
        weight: 600;
      }
      a {
        color: $dark-color;

        &:hover,
        &:focus {
          color: $repair-main-color;
          text-decoration: none;
        }
      }
    }
    ul {
      list-style-type: none;
      margin: {
        top: 15px;
        bottom: 0;
      }
      border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
      }
      padding: {
        bottom: 15px;
        top: 15px;
        left: 0;
      }
      li {
        display: inline-block;
        color: $pcolor;
        position: relative;
        margin-right: 18px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          height: 2px;
          background: $repair-main-color;
          left: -13px;
        }
        a {
          color: $pcolor;

          &:hover,
          &:focus {
            color: $repair-main-color;
            text-decoration: none;
          }
        }
        &:first-child {
          &::before {
            display: none;
          }
        }
        &:last-child {
          float: right;
          margin-right: 0;

          &::before {
            display: none;
          }
          a {
            text-transform: uppercase;
            color: $orange-color;

            &:hover,
            &:focus {
              color: $repair-main-color;
            }
          }
        }
      }
    }
  }
  &:hover,
  &:focus {
    .blog-image {
      a {
        img {
          transform: scale(1.3);
        }
      }
    }
  }
}
/* ==============
    End Repair Blog CSS 
==================================== */

/* ==============
    Start Repair Subscribe CSS 
==================================== */
.repair-subscribe-area {
  position: relative;
  margin: {
    top: -100px;
    bottom: 100px;
  }
}
.repair-subscribe-content {
  text-align: center;
  border-radius: 5px;
  padding: {
    top: 40px;
    bottom: 40px;
    left: 30px;
    right: 30px;
  }
  h2 {
    color: $white-color;
    margin-bottom: 30px;
    font: {
      size: 30px;
    }
  }
  form {
    overflow: hidden;

    .form-group {
      float: left;
      margin-bottom: 0;
      width: 29%;
      padding-right: 15px;

      .input-newsletter {
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $white-color;
        background: transparent;
        outline: 0;
        color: $white-color;
        padding: {
          bottom: 10px;
          top: 12px;
        }
        &::placeholder {
          color: $white-color;
        }
      }
    }
    .validation-danger,
    .validation-success {
      color: $white-color;
    }
    .btn {
      float: left;
      width: 13%;
    }
    .btn-primary {
      color: $white-color;
      border-color: $orange-color;
      background-color: $orange-color;

      &.disabled,
      &:disabled {
        color: $white-color;
        background-color: $orange-color;
        border-color: $orange-color;
      }
    }
  }
}
/* ==============
    End Repair Subscribe CSS 
==================================== */

/* ==============
    Start Repair Footer CSS 
==================================== */
.repair-footer-area {
  padding-top: 100px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 201%;
    background: #161616;
    z-index: -2;
    transform: skewY(177deg);
  }
}
.single-footer-widget {
  .logo {
    margin-top: -10px;

    a {
      color: $white-color;
      text-transform: uppercase;
      display: block;
      font: {
        size: 30px;
        weight: 700;
      }
      span {
        color: $repair-main-color;
        display: inline-block;
      }
    }
  }
  p {
    color: #f2f2f2;
    margin: {
      top: 15px;
      bottom: 20px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  h3 {
    color: $white-color;
    margin-bottom: 27px;
    font: {
      size: 20px;
      weight: 600;
    }
  }
  .services-list {
    li {
      a {
        margin-bottom: 12px;
        display: inline-block;
        color: #f2f2f2;

        &:hover {
          padding-left: 10px;
          color: $orange-color;
        }
      }
      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }
  .usefull-links {
    li {
      a {
        margin-bottom: 12px;
        display: inline-block;
        color: #f2f2f2;

        &:hover {
          padding-left: 10px;
          color: $orange-color;
        }
      }
      &:last-child {
        a {
          margin-bottom: 0;
        }
      }
    }
  }
  .contact-list {
    li {
      margin-bottom: 12px;
      color: #f2f2f2;
      position: relative;

      a {
        color: #f2f2f2;
        display: inline-block;

        &:hover {
          color: $orange-color;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: 600;
      }
    }
  }
  .working-hours {
    li {
      margin-bottom: 12px;
      color: #f2f2f2;
      position: relative;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.social-links {
  li {
    display: inline-block;
    margin-right: 2px;

    a {
      width: 35px;
      height: 35px;
      text-align: center;
      display: inline-block;
      line-height: 35px;
      border-radius: 50%;
      font-size: 14px;
      background-color: #343434;
      color: $white-color;

      &:hover,
      &:focus {
        background: $orange-color;
        color: $white-color;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.repair-copyright-area {
  background: #000000;
  margin-top: 100px;
  padding: {
    top: 30px;
    bottom: 30px;
  }
  p {
    color: $white-color;
    font-size: 15px;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      position: relative;
      padding-right: 20px;
      font-size: 15px;

      &::before {
        content: "";
        position: absolute;
        left: -13px;
        top: 5px;
        background: #7c7c7c;
        width: 2px;
        height: 14px;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
      a {
        display: inline-block;
        color: #f8f8f8;

        &:hover {
          color: $orange-color;
        }
      }
    }
  }
}
/* ==============
    End Repair Footer CSS 
==================================== */

.CalendarDay__highlighted_calendar {
  // background-color: $secondary-color !important;
  background-color: orangered !important;
  color: #fff !important;

  &:hover,
  &.CalendarDay__today {
    // background-color: darken($secondary-color, 5%) !important;
    background-color: darken(orangered, 5%) !important;
  }
}

.CalendarDay__blocked_calendar {
  background-color: lighten(#cacccd, 10%) !important;

  &.CalendarDay__today {
    background-color: #cacccd !important;
  }
}

.DayPicker {
  margin: auto;
}

.DayPicker__infos {
  div {
    display: inline-block;
    height: 18px;
    width: 18px;

    &.available {
      background-color: $secondary-color;
    }

    &.unavailable {
      background-color: #cacccd;
    }

    &.booked {
      background-color: orangered;
    }
  }
}

.fallback {
  .spinner-border {
    color: $theme-color;
  }
}
